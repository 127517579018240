import { useEffect } from 'react';

const ModuleJs1 = () => {
  useEffect(() => {
    const scriptUrls = [
      `${process.env.PUBLIC_URL}/assets/js/jquery.js`,
  `${process.env.PUBLIC_URL}/assets/js/popper.min.js`,
  `${process.env.PUBLIC_URL}/assets/js/bootstrap.min.js`,
  `${process.env.PUBLIC_URL}/assets/js/owl.js`,
  `${process.env.PUBLIC_URL}/assets/js/wow.js`,
  `${process.env.PUBLIC_URL}/assets/js/validation.js`,
  `${process.env.PUBLIC_URL}/assets/js/jquery.fancybox.js`,
  `${process.env.PUBLIC_URL}/assets/js/appear.js`,
  `${process.env.PUBLIC_URL}/assets/js/scrollbar.js`,
  `${process.env.PUBLIC_URL}/assets/js/isotope.js`,
  `${process.env.PUBLIC_URL}/assets/js/jquery.nice-select.min.js`,
  `${process.env.PUBLIC_URL}/assets/js/nav-tool.js`,
  `${process.env.PUBLIC_URL}/assets/js/jquery.lettering.min.js`,
  `${process.env.PUBLIC_URL}/assets/js/jquery.circleType.js`,
  `${process.env.PUBLIC_URL}/assets/js/bxslider.js`,
  `${process.env.PUBLIC_URL}/assets/js/script.js`,
 ];

 const scripts = scriptUrls.map(url => {
  const script = document.createElement('script');
  script.src = url;
  script.async = false;
  document.body.appendChild(script);
  return script;
});

// Retourne une fonction de nettoyage unique pour tous les scripts
return () => {
  scripts.forEach(script => {
    document.body.removeChild(script);
  });
};

  }, []);

  return null; // Ce composant ne rend rien visuellement
};

export default ModuleJs1;
