import { useEffect } from 'react';

const ModuleJs2 = () => {
  useEffect(() => {
    const scriptUrls = [
      "vue1/js/jquery-3.6.0.min.js",
      "vue1/js/popper.js",
      "vue1/js/bootstrap.min.js",
      "vue1/js/mega-menu/mega_menu.js",
      "vue1/js/jquery.appear.js",
      "vue1/js/counter/jquery.countTo.js",
      "vue1/js/isotope/isotope.pkgd.min.js",
      "vue1/js/owl-carousel/owl.carousel.min.js",
      "vue1/js/select/jquery-select.js",
      "vue1/js/magnific-popup/jquery.magnific-popup.min.js",
      "vue1/revolution/js/jquery.themepunch.tools.min.js",
      "vue1/revolution/js/jquery.themepunch.revolution.min.js",
      "vue1/revolution/js/extensions/revolution.extension.actions.min.js",
      "vue1/revolution/js/extensions/revolution.extension.carousel.min.js",
      "vue1/revolution/js/extensions/revolution.extension.kenburn.min.js",
      "vue1/revolution/js/extensions/revolution.extension.layeranimation.min.js",
      "vue1/revolution/js/extensions/revolution.extension.migration.min.js",
      "vue1/revolution/js/extensions/revolution.extension.navigation.min.js",
      "vue1/revolution/js/extensions/revolution.extension.parallax.min.js",
      "vue1/revolution/js/extensions/revolution.extension.slideanims.min.js",
      "vue1/revolution/js/extensions/revolution.extension.video.min.js",
      "vue1/js/custom.js"
    ];

    // Crée et ajoute dynamiquement chaque script au document
    const scripts = scriptUrls.map(url => {
      const script = document.createElement('script');
      script.src = `${process.env.PUBLIC_URL}/${url}`;
      script.async = false; // Important si l'ordre de chargement est crucial
      document.body.appendChild(script);

      return script;
    });

    // Fonction de nettoyage pour retirer les scripts lors du démontage
    return () => {
      scripts.forEach(script => {
        document.body.removeChild(script);
      });
    };

  }, []);

  return null; // Ce composant ne rend rien visuellement
};

export default ModuleJs2;
