import React from 'react';
import Loader from '../Loader2'
import {
    createBrowserRouter,
    RouterProvider,
  } from "react-router-dom";
  import routesConfig from '../routes';
  const router = createBrowserRouter(
    routesConfig.map(({ path, header: Header}) => ({
      path, // Utilisation de raccourci ES6 pour { path: path }
      element: <Header />,
      // errorElement: <MonComposantErreur /> // Décommentez si vous avez un composant d'erreur spécifique
    }))
  );

function Header() {
    return (
<>
<RouterProvider router={router} />
</>
        
  
    );
  }
  
  export default Header;
