import React from 'react';

function Loader() {
    return (
        <div className="loader-wrap">
            <div className="preloader">
        <div className="preloader-close">x</div>
        <div id="handle-preloader" className="handle-preloader">
            <div className="animation-preloader">
                <div className="spinner"></div>
                <div className="txt-loading">à
                    <span data-text-preloader="A" className="letters-loading">
                        A
                    </span>
                    <span data-text-preloader="P" className="letters-loading">
                        P   
                    </span>
                    <span data-text-preloader="I" className="letters-loading">
                        I
                    </span>
                    
                </div>
            </div>
        </div>
    </div>
        </div>
    );
}

export default Loader;
