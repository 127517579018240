import React from 'react';
function Home2() {
    return (
      <>
  <section className="slider-parallax car-directory-banner bg-overlay-black-70 bg-17">
    <div className="slider-content-middle">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="slider-content text-center">
              <h2 className="text-white">Vous souhaitez devenir copropriétaire d'un de nos équipements ?</h2>
              <h4 className="text-white">
                Plus de <strong className="text-red">4000</strong> équipements disponibles en 
                <strong className="text-red"> investissement</strong>
              </h4>
              <div className="search-tab">
                <div id="search-tabs">
                 
                  <div className="tab-content" id="myTabContent02">
                    <div
                      className="tab-pane fade show active"
                      id="all-cars"
                      role="tabpanel"
                      aria-labelledby="all-cars-tab"
                    >
                      <div className="row">
                        <div className="col-lg-2 col-md-6">
                          <div className="selected-box">
                            <select className="selectpicker">
                              <option>Catégorie </option>
                              <option>BMW</option>
                              <option>Honda </option>
                              <option>Hyundai </option>
                              <option>Nissan </option>
                              <option>Mercedes Benz </option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                          <div className="selected-box">
                            <select className="selectpicker">
                              <option>État</option>
                              <option>3-Series</option>
                              <option>Carrera</option>
                              <option>GT-R</option>
                              <option>Cayenne</option>
                              <option>Mazda6</option>
                              <option>Macan</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                          <div className="selected-box">
                            <select className="selectpicker">
                              <option>fourchette </option>
                              <option>$5,000</option>
                              <option>$10,000</option>
                              <option>$15,000</option>
                              <option>$20,000</option>
                              <option>$25,000</option>
                              <option>$30,000</option>
                              <option>$35,000</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                          <div className="selected-box">
                            <select className="selectpicker">
                              <option>Rendement  </option>
                              <option>$5,000</option>
                              <option>$10,000</option>
                              <option>$15,000</option>
                              <option>$20,000</option>
                              <option>$25,000</option>
                              <option>$30,000</option>
                              <option>$35,000</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-2 col-md-6">
                          <div className="selected-box">
                            <select className="selectpicker">
                              <option>Fréquence  </option>
                              <option>$5,000</option>
                              <option>$10,000</option>
                              <option>$15,000</option>
                              <option>$20,000</option>
                              <option>$25,000</option>
                              <option>$30,000</option>
                              <option>$35,000</option>
                            </select>
                          </div>
                        </div>
                        
                        <div className="col-lg-2 col-md-12">
                          <div className="d-grid">
                            <button className="button red" type="button">
                              Search
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section className="custom-block-3 white-bg page-section-ptb">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <div className="title">
            <h3>Notre Mission</h3>
          </div>
          <div className="content">
            <h2 className="text-red">API</h2>
            <strong>
            La solution innovante pour devenir copropriétaire des équipements les plus rentables dans la location.
            </strong>
            <p>
            <b>AUTO PARTAGE INVEST</b> est la plateforme idéale conçue spécifiquement pour les <b>investisseurs</b> cherchant à diversifier leur portefeuille en participant à l'économie du partage. Grâce à notre modèle unique de <b>copropriété</b>, nous offrons une opportunité sans précédent d'investir dans une <b>variété d'équipements</b>, allant des <b>véhicules aux machines de BTP et équipements de sport</b>.
              
            </p>
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <img className="img-fluid" src="assets/images/car/11.png" alt="" />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 col-md-6">
          <div className="feature-box-3 grey-border">
            <div className="icon">
              <i className="fa fa-line-chart" />
            </div>
            <div className="content">
              <h6>Rentabilité Assurée</h6>
              <p>Des revenus stables grâce à une demande locative constante.</p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="feature-box-3 grey-border">
            <div className="icon">
              <i className="fa fa-lock" />
            </div>
            <div className="content">
              <h6>Sécurisé</h6>
              <p>Protection juridique et assurance complète de vos biens.</p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="feature-box-3 grey-border">
            <div className="icon">
              <i className="fa fa-eye" />
            </div>
            <div className="content">
              <h6>Transparence</h6>
              <p>Accès clair et direct à tous les aspects de votre investissement.</p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="feature-box-3">
            <div className="icon">
              <i className="fa fa-headphones" />
            </div>
            <div className="content">
              <h6>Assistance Dédiée</h6>
              <p>Notre équipe d'assistance est là pour vous accompagner à chaque étape.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*=================================
 custom block */}
  {/*=================================
 feature-car */}
  <section className="feature-car gray-bg page-section-ptb">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="section-title">
            <span>Trouvez votre prochain cheval de courses</span>
            <h2>Équipements Vedettes </h2>
            <div className="separator" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div
            className="owl-carousel owl-theme"
            data-nav-arrow="true"
            data-items={4}
            data-md-items={4}
            data-sm-items={2}
            data-xs-items={2}
            data-space={15}
          >
            <div className="item">
              <div className="car-item text-center">
                <div className="car-image">
                  <img className="img-fluid" src="assets/images/car/01.jpg" alt="" />
                  <div className="car-overlay-banner">
                    <ul>
                      <li>
                        <a href="index-6.html#">
                          <i className="fa fa-link" />
                        </a>
                      </li>
                      <li>
                        <a href="index-6.html#">
                          <i className="fa fa-dashboard" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="car-list">
        <ul className="list-inline">
          <li><i className="fa fa-calendar" /> 2023</li>
          <li><i className="fa fa-gear" /> Automatique</li>
          <li><i className="fa fa-dashboard" /> 0 km</li>
        </ul>
      </div>
      <div className="car-content">
        <a href="/">Véhicule Électrique de Luxe</a>
        <div className="separator" />
        <div className="price">
          <span className="new-price">Investissement Minimum : 5 000 000 FCFA</span>
        </div>
      </div>
              </div>
            </div>
            <div className="item">
              <div className="car-item text-center">
                <div className="car-image">
                  <img className="img-fluid" src="assets/images/car/02.jpg" alt="" />
                  <div className="car-overlay-banner">
                    <ul>
                      <li>
                        <a href="index-6.html#">
                          <i className="fa fa-link" />
                        </a>
                      </li>
                      <li>
                        <a href="index-6.html#">
                          <i className="fa fa-dashboard" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="car-list">
        <ul className="list-inline">
          <li><i className="fa fa-calendar" /> 2023</li>
          <li><i className="fa fa-heartbeat" /> Cardio</li>
          <li><i className="fa fa-bolt" /> Électrique</li>
        </ul>
      </div>
      <div className="car-content">
        <a href="/">Tapis de Course Professionnel</a>
        <div className="separator" />
        <div className="price">
          <span className="new-price">Investissement Minimum : 750 000 FCFA</span>
        </div>
      </div>
              </div>
            </div>
            <div className="item">
              <div className="car-item text-center">
                <div className="car-image">
                  <img className="img-fluid" src="assets/images/car/03.jpg" alt="" />
                  <div className="car-overlay-banner">
                    <ul>
                      <li>
                        <a href="index-6.html#">
                          <i className="fa fa-link" />
                        </a>
                      </li>
                      <li>
                        <a href="index-6.html#">
                          <i className="fa fa-dashboard" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="car-list">
        <ul className="list-inline">
          <li><i className="fa fa-calendar" /> 2023</li>
          <li><i className="fa fa-microchip" /> i7, 16GB RAM</li>
          <li><i className="fa fa-hdd-o" /> SSD 512GB</li>
        </ul>
      </div>
      <div className="car-content">
        <a href='/'>Ordinateur Portable Haute Performance</a>
        <div className="separator" />
        <div className="price">
          <span className="new-price">Investissement Minimum : 2 500 000 FCFA</span>
        </div>
      </div>
              </div>
            </div>
            <div className="item">
              <div className="car-item text-center">
                <div className="car-image">
                  <img className="img-fluid" src="assets/images/car/04.jpg" alt="" />
                  <div className="car-overlay-banner">
                    <ul>
                      <li>
                        <a href="index-6.html#">
                          <i className="fa fa-link" />
                        </a>
                      </li>
                      <li>
                        <a href="index-6.html#">
                          <i className="fa fa-dashboard" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="car-list">
                  <ul className="list-inline">
                    <li>
                      <i className="fa fa-registered" /> 2021
                    </li>
                    <li>
                      <i className="fa fa-cog" /> Manual
                    </li>
                    <li>
                      <i className="fa fa-dashboard" /> 6,000 mi
                    </li>
                  </ul>
                </div>
                <div className="car-content">
                  <div className="star">
                    <i className="fa fa-star orange-color" />
                    <i className="fa fa-star orange-color" />
                    <i className="fa fa-star orange-color" />
                    <i className="fa fa-star orange-color" />
                    <i className="fa fa-star-o orange-color" />
                  </div>
                  <a href="index-6.html#">Toyota avalon hybrid </a>
                  <div className="separator" />
                  <div className="price">
                    <span className="old-price">$63,568</span>
                    <span className="new-price">$60,698 </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="car-item text-center">
                <div className="car-image">
                  <img className="img-fluid" src="assets/images/car/05.jpg" alt="" />
                  <div className="car-overlay-banner">
                    <ul>
                      <li>
                        <a href="index-6.html#">
                          <i className="fa fa-link" />
                        </a>
                      </li>
                      <li>
                        <a href="index-6.html#">
                          <i className="fa fa-dashboard" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="car-list">
                  <ul className="list-inline">
                    <li>
                      <i className="fa fa-registered" /> 2021
                    </li>
                    <li>
                      <i className="fa fa-cog" /> Manual{" "}
                    </li>
                    <li>
                      <i className="fa fa-dashboard" /> 6,000 mi
                    </li>
                  </ul>
                </div>
                <div className="car-content">
                  <div className="star">
                    <i className="fa fa-star orange-color" />
                    <i className="fa fa-star orange-color" />
                    <i className="fa fa-star orange-color" />
                    <i className="fa fa-star orange-color" />
                    <i className="fa fa-star-o orange-color" />
                  </div>
                  <a href="index-6.html#">Hyundai santa fe sport </a>
                  <div className="separator" />
                  <div className="price">
                    <span className="old-price">$45,568</span>
                    <span className="new-price">$40,698 </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section className="our-service white-bg page-section-ptb">
    <div className="container">
      <div className="row">
      <div className="col-lg-7 col-md-12">
        <h2>Comment Ça Fonctionne ?</h2>
        <span>
         <b> AutoPartage Invest propose un processus d'investissement clair et structuré, conçu pour faciliter l'accès à la copropriété d'équipements variés et à la génération de revenus passifs.</b>
        </span>
        <div className="row">
          <div className="col-md-6">
            <div className="feature-box-2">
              <div className="icon">
                <i className="fa fa-search-plus" aria-hidden="true"></i>
              </div>
              <div className="content">
                <h5>Étape 1 : Sélection des Équipements</h5>
                <p>
                  Incluant des véhicules, du matériel de BTP, et des équipements de sport.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="feature-box-2">
              <div className="icon">
                <i className="fa fa-line-chart" aria-hidden="true"></i>
              </div>
              <div className="content">
                <h5>Étape 2 : Investissement</h5>
                <p>
                  Souscription à des parts de copropriété et établissement d'un contrat formalisant l'investissement.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="feature-box-2">
              <div className="icon">
                <i className="fa fa-wrench" aria-hidden="true"></i>
              </div>
              <div className="content">
                <h5>Étape 3 : Gestion et Entretien</h5>
                <p>
                  Gestion quotidienne, entretien, et assurance.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="feature-box-2">
              <div className="icon">
                <i className="fa fa-usd" aria-hidden="true"></i>
              </div>
              <div className="content">
                <h5>Étape 4 : Répartition des Revenus</h5>
                <p>
                  Distribution des profits aux copropriétaires.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="feature-box-2">
          <div className="icon">
            <i className="fa fa-eye" aria-hidden="true"></i>
          </div>
          <div className="content">
            <h5>Étape 5 : Suivi et Transparence</h5>
            <p>
              Accès à une plateforme de suivi pour une visibilité complète.
            </p>
          </div>
        </div>
      </div>
        <div className="col-lg-5 col-md-12 mt-4 mt-lg-0">
          <img className="img-fluid" src="assets/images/car/12.jpg" alt="" />
        </div>
      </div>
    </div>
  </section>
  {/*=================================
 our-service */}
  {/*=================================
 Counter */}
  <section className="counter counter-style-2 bg-1 bg-overlay-black-70 page-section-ptb">
    <div className="container">
      <div className="row">
        <div className="col-lg-3 col-sm-6 item">
          <div className="counter-block text-start">
            <div className="separator" />
            <div className="info">
              <h6 className="text-white">Vehicles in Stock</h6>
              <i className="glyph-icon flaticon-beetle" />
              <b
                className="timer text-white"
                data-to={561}
                data-speed={10000}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 item">
          <div className="counter-block text-start">
            <div className="separator" />
            <div className="info">
              <h6 className="text-white">Dealer Reviews</h6>
              <i className="glyph-icon flaticon-interface" />
              <b
                className="timer text-white"
                data-to={856}
                data-speed={10000}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 item">
          <div className="counter-block text-start">
            <div className="separator" />
            <div className="info">
              <h6 className="text-white">Happy Customer</h6>
              <i className="glyph-icon flaticon-circle" />
              <b
                className="timer text-white"
                data-to={789}
                data-speed={10000}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 item">
          <div className="counter-block text-start mb-0">
            <div className="separator" />
            <div className="info">
              <h6 className="text-white">Awards</h6>
              <i className="glyph-icon flaticon-cup" />
              <b
                className="timer text-white"
                data-to={356}
                data-speed={10000}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*=================================
 Counter */}
  {/*=================================
 recent-vehicle*/}
  <section className="recent-vehicle white-bg page-section-pt">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="section-title">
            <span>Check out our recent cars</span>
            <h2>RECENT VEHICLE</h2>
            <div className="separator" />
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid p-0">
      <div className="row g-0">
        <div className="col-md-12">
          <div className="isotope-filters">
            <button data-filter="" className="active">
              All
            </button>
            <button data-filter=".bmw">bmw</button>
            <button data-filter=".mercedes">mercedes</button>
            <button data-filter=".audi">audi</button>
            <button data-filter=".hyundai">hyundai</button>
          </div>
        </div>
      </div>
      <div className="row g-0">
        <div className="col-md-12">
          <div className="isotope popup-gallery column-5">
            <div className="grid-item mercedes audi">
              <div className="car-item-3">
                <img
                  className="img-fluid center-block"
                  src="assets/images/car/01.jpg"
                  alt=""
                />
                <div className="car-popup">
                  <a className="popup-img" href="assets/images/car/01.jpg">
                    <i className="fa fa-plus" />
                  </a>
                </div>
                <div className="car-overlay text-center">
                  <a className="link" href="index-6.html#">
                    Acura Rsx
                  </a>
                  <span className="price">$32,698</span>
                </div>
              </div>
            </div>
            <div className="grid-item bmw audi">
              <div className="car-item-3">
                <img
                  className="img-fluid center-block"
                  src="assets/images/car/02.jpg"
                  alt=""
                />
                <div className="car-popup">
                  <a className="popup-img" href="assets/images/car/02.jpg">
                    <i className="fa fa-plus" />
                  </a>
                </div>
                <div className="car-overlay text-center">
                  <a className="link" href="index-6.html#">
                    Lexus GS 450h
                  </a>
                  <span className="price">$32,698</span>
                </div>
              </div>
            </div>
            <div className="grid-item mercedes hyundai">
              <div className="car-item-3">
                <img
                  className="img-fluid center-block"
                  src="assets/images/car/03.jpg"
                  alt=""
                />
                <div className="car-popup">
                  <a className="popup-img" href="assets/images/car/03.jpg">
                    <i className="fa fa-plus" />
                  </a>
                </div>
                <div className="car-overlay text-center">
                  <a className="link" href="index-6.html#">
                    GTA 5 Lowriders DLC
                  </a>
                  <span className="price">$32,698</span>
                </div>
              </div>
            </div>
            <div className="grid-item bmw audi">
              <div className="car-item-3">
                <img
                  className="img-fluid center-block"
                  src="assets/images/car/04.jpg"
                  alt=""
                />
                <div className="car-popup">
                  <a className="popup-img" href="assets/images/car/04.jpg">
                    <i className="fa fa-plus" />
                  </a>
                </div>
                <div className="car-overlay text-center">
                  <a className="link" href="index-6.html#">
                    Toyota avalon hybrid{" "}
                  </a>
                  <span className="price">$32,698</span>
                </div>
              </div>
            </div>
            <div className="grid-item mercedes hyundai">
              <div className="car-item-3">
                <img
                  className="img-fluid center-block"
                  src="assets/images/car/05.jpg"
                  alt=""
                />
                <div className="car-popup">
                  <a className="popup-img" href="assets/images/car/05.jpg">
                    <i className="fa fa-plus" />
                  </a>
                </div>
                <div className="car-overlay text-center">
                  <a className="link" href="index-6.html#">
                    Hyundai santa fe sport{" "}
                  </a>
                  <span className="price">$32,698</span>
                </div>
              </div>
            </div>
            <div className="grid-item bmw audi hyundai">
              <div className="car-item-3">
                <img
                  className="img-fluid center-block"
                  src="assets/images/car/06.jpg"
                  alt=""
                />
                <div className="car-popup">
                  <a className="popup-img" href="assets/images/car/06.jpg">
                    <i className="fa fa-plus" />
                  </a>
                </div>
                <div className="car-overlay text-center">
                  <a className="link" href="index-6.html#">
                    Lexus is f
                  </a>
                  <span className="price">$32,698</span>
                </div>
              </div>
            </div>
            <div className="grid-item bmw hyundai">
              <div className="car-item-3">
                <img
                  className="img-fluid center-block"
                  src="assets/images/car/07.jpg"
                  alt=""
                />
                <div className="car-popup">
                  <a className="popup-img" href="assets/images/car/07.jpg">
                    <i className="fa fa-plus" />
                  </a>
                </div>
                <div className="car-overlay text-center">
                  <a className="link" href="index-6.html#">
                    Hyundai santa fe
                  </a>
                  <span className="price">$32,698</span>
                </div>
              </div>
            </div>
            <div className="grid-item mercedes hyundai">
              <div className="car-item-3">
                <img
                  className="img-fluid center-block"
                  src="assets/images/car/01.jpg"
                  alt=""
                />
                <div className="car-popup">
                  <a className="popup-img" href="assets/images/car/01.jpg">
                    <i className="fa fa-plus" />
                  </a>
                </div>
                <div className="car-overlay text-center">
                  <a className="link" href="index-6.html#">
                    Acura Rsx
                  </a>
                  <span className="price">$32,698</span>
                </div>
              </div>
            </div>
            <div className="grid-item mercedes hyundai">
              <div className="car-item-3">
                <img
                  className="img-fluid center-block"
                  src="assets/images/car/02.jpg"
                  alt=""
                />
                <div className="car-popup">
                  <a className="popup-img" href="assets/images/car/02.jpg">
                    <i className="fa fa-plus" />
                  </a>
                </div>
                <div className="car-overlay text-center">
                  <a className="link" href="index-6.html#">
                    Lexus GS 450h
                  </a>
                  <span className="price">$32,698</span>
                </div>
              </div>
            </div>
            <div className="grid-item bmw audi">
              <div className="car-item-3">
                <img
                  className="img-fluid center-block"
                  src="assets/images/car/03.jpg"
                  alt=""
                />
                <div className="car-popup">
                  <a className="popup-img" href="assets/images/car/03.jpg">
                    <i className="fa fa-plus" />
                  </a>
                </div>
                <div className="car-overlay text-center">
                  <a className="link" href="index-6.html#">
                    GTA 5 Lowriders DLC
                  </a>
                  <span className="price">$32,698</span>
                </div>
              </div>
            </div>
            <div className="grid-item mercedes hyundai">
              <div className="car-item-3">
                <img
                  className="img-fluid center-block"
                  src="assets/images/car/04.jpg"
                  alt=""
                />
                <div className="car-popup">
                  <a className="popup-img" href="assets/images/car/04.jpg">
                    <i className="fa fa-plus" />
                  </a>
                </div>
                <div className="car-overlay text-center">
                  <a className="link" href="index-6.html#">
                    {" "}
                    Toyota avalon hybrid{" "}
                  </a>
                  <span className="price">$32,698</span>
                </div>
              </div>
            </div>
            <div className="grid-item bmw audi hyundai">
              <div className="car-item-3">
                <img
                  className="img-fluid center-block"
                  src="assets/images/car/05.jpg"
                  alt=""
                />
                <div className="car-popup">
                  <a className="popup-img" href="assets/images/car/05.jpg">
                    <i className="fa fa-plus" />
                  </a>
                </div>
                <div className="car-overlay text-center">
                  <a className="link" href="index-6.html#">
                    Hyundai santa fe sport{" "}
                  </a>
                  <span className="price">$32,698</span>
                </div>
              </div>
            </div>
            <div className="grid-item bmw hyundai">
              <div className="car-item-3">
                <img
                  className="img-fluid center-block"
                  src="assets/images/car/06.jpg"
                  alt=""
                />
                <div className="car-popup">
                  <a className="popup-img" href="assets/images/car/06.jpg">
                    <i className="fa fa-plus" />
                  </a>
                </div>
                <div className="car-overlay text-center">
                  <a className="link" href="index-6.html#">
                    Lexus is f
                  </a>
                  <span className="price">$32,698</span>
                </div>
              </div>
            </div>
            <div className="grid-item mercedes hyundai">
              <div className="car-item-3">
                <img
                  className="img-fluid center-block"
                  src="assets/images/car/07.jpg"
                  alt=""
                />
                <div className="car-popup">
                  <a className="popup-img" href="assets/images/car/07.jpg">
                    <i className="fa fa-plus" />
                  </a>
                </div>
                <div className="car-overlay text-center">
                  <a className="link" href="index-6.html#">
                    Hyundai santa fe
                  </a>
                  <span className="price">$32,698</span>
                </div>
              </div>
            </div>
            <div className="grid-item mercedes hyundai">
              <div className="car-item-3">
                <img
                  className="img-fluid center-block"
                  src="assets/images/car/02.jpg"
                  alt=""
                />
                <div className="car-popup">
                  <a className="popup-img" href="assets/images/car/02.jpg">
                    <i className="fa fa-plus" />
                  </a>
                </div>
                <div className="car-overlay text-center">
                  <a className="link" href="index-6.html#">
                    Toyota avalon hybrid{" "}
                  </a>
                  <span className="price">$32,698</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*=================================
 recent-vehicle*/}
  {/*=================================
  content-box */}
  <section className="content-box-main">
    <div className="container-lg p-0">
      <div className="row g-0">
        <div className="col-lg-6 col-md-12 gray-bg overlay-bg-left position-relative">
          <div className="content-box-5">
            <div className="content-info">
              <a href="index-6.html#">Are you looking for a car?</a>
              <p>
                Search your car in our Inventory and request a quote on the
                vehicle of your choosing.{" "}
              </p>
            </div>
            <div className="content-box-img text-center">
              <i className="glyph-icon flaticon-beetle" />
              <img
                className="img-fluid center-block"
                src="assets/images/car/13.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 overlay-bg-right dark-bg position-relative">
          <div className="content-box-5">
            <div className="content-info">
              <a href="index-6.html#">Do you want to sell your car?</a>
              <p>
                Request search your car in our Inventory and a quote on the
                vehicle of your choosing.{" "}
              </p>
            </div>
            <div className="content-box-img text-center">
              <i className="glyph-icon flaticon-key " />
              <img
                className="img-fluid center-block"
                src="assets/images/car/14.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*=================================
 content-box */}
  {/*=================================
 latest blog */}
  <section className="latest-blog white-bg page-section-ptb">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="section-title">
            <span>Read our latest news</span>
            <h2>latest news </h2>
            <div className="separator" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-12">
          <div className="blog-2">
            <div className="blog-image">
              <img className="img-fluid" src="assets/images/blog/05.jpg" alt="" />
              <div className="date">
                <span>AUG 21</span>
              </div>
            </div>
            <div className="blog-content">
              <div className="blog-admin-main">
                <div className="blog-admin">
                  <img className="img-fluid" src="assets/images/team/01.jpg" alt="" />
                  <span>John Doe</span>
                </div>
                <div className="blog-meta float-end">
                  <ul>
                    <li>
                      <a href="index-6.html#">
                        {" "}
                        <i className="fa fa-comment" />
                        <br /> 123
                      </a>
                    </li>
                    <li className="share">
                      <a href="index-6.html#">
                        {" "}
                        <i className="fa fa-share-alt" />
                        <br /> ...
                      </a>
                      <div className="blog-social">
                        <ul>
                          <li>
                            {" "}
                            <a href="index-6.html#">
                              <i className="fa fa-facebook" />
                            </a>{" "}
                          </li>
                          <li>
                            {" "}
                            <a href="index-6.html#">
                              <i className="fa fa-twitter" />
                            </a>{" "}
                          </li>
                          <li>
                            {" "}
                            <a href="index-6.html#">
                              <i className="fa fa-instagram" />
                            </a>{" "}
                          </li>
                          <li>
                            {" "}
                            <a href="index-6.html#">
                              <i className="fa fa-pinterest-p" />
                            </a>{" "}
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="blog-description text-center">
                <a href="index-6.html#">Does Your Life Lack Meaning</a>
                <div className="separator" />
                <p>
                  You will begin to realize why this exercise Pattern is called
                  the Dickens with reference to the ghost
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-12">
          <div className="blog-2">
            <div className="blog-image">
              <img className="img-fluid" src="assets/images/blog/06.jpg" alt="" />
              <div className="date">
                <span>AUG 21</span>
              </div>
            </div>
            <div className="blog-content">
              <div className="blog-admin-main">
                <div className="blog-admin">
                  <img className="img-fluid" src="assets/images/team/02.jpg" alt="" />
                  <span>Paul Flavius</span>
                </div>
                <div className="blog-meta float-end">
                  <ul>
                    <li>
                      <a href="index-6.html#">
                        {" "}
                        <i className="fa fa-comment" />
                        <br /> 123
                      </a>
                    </li>
                    <li className="share">
                      <a href="index-6.html#">
                        {" "}
                        <i className="fa fa-share-alt" />
                        <br /> ...
                      </a>
                      <div className="blog-social">
                        <ul>
                          <li>
                            {" "}
                            <a href="index-6.html#">
                              <i className="fa fa-facebook" />
                            </a>{" "}
                          </li>
                          <li>
                            {" "}
                            <a href="index-6.html#">
                              <i className="fa fa-twitter" />
                            </a>{" "}
                          </li>
                          <li>
                            {" "}
                            <a href="index-6.html#">
                              <i className="fa fa-instagram" />
                            </a>{" "}
                          </li>
                          <li>
                            {" "}
                            <a href="index-6.html#">
                              <i className="fa fa-pinterest-p" />
                            </a>{" "}
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="blog-description text-center">
                <a href="index-6.html#">The A Z Of Motivation</a>
                <div className="separator" />
                <p>
                  Exercise is called you will begin to Pattern realize why this
                  the Dickens Pattern with reference to ghost
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-12">
          <div className="blog-2 mb-0">
            <div className="blog-image">
              <img className="img-fluid" src="assets/images/blog/07.jpg" alt="" />
              <div className="date">
                <span>AUG 21</span>
              </div>
            </div>
            <div className="blog-content">
              <div className="blog-admin-main">
                <div className="blog-admin">
                  <img className="img-fluid" src="assets/images/team/03.jpg" alt="" />
                  <span>Sara Lisbon</span>
                </div>
                <div className="blog-meta float-end">
                  <ul>
                    <li>
                      <a href="index-6.html#">
                        {" "}
                        <i className="fa fa-comment" />
                        <br /> 123
                      </a>
                    </li>
                    <li className="share">
                      <a href="index-6.html#">
                        {" "}
                        <i className="fa fa-share-alt" />
                        <br /> ...
                      </a>
                      <div className="blog-social">
                        <ul>
                          <li>
                            {" "}
                            <a href="index-6.html#">
                              <i className="fa fa-facebook" />
                            </a>{" "}
                          </li>
                          <li>
                            {" "}
                            <a href="index-6.html#">
                              <i className="fa fa-twitter" />
                            </a>{" "}
                          </li>
                          <li>
                            {" "}
                            <a href="index-6.html#">
                              <i className="fa fa-instagram" />
                            </a>{" "}
                          </li>
                          <li>
                            {" "}
                            <a href="index-6.html#">
                              <i className="fa fa-pinterest-p" />
                            </a>{" "}
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="blog-description text-center">
                <a href="index-6.html#">Motivation In Life</a>
                <div className="separator" />
                <p>
                  Dickens Pattern you will begin to realize why this Dickens
                  exercise is the with reference to the ghost
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*=================================
 latest blog */}
  {/*=================================
testimonial */}
  <section className="testimonial-2 bg-1 bg-overlay-black-70 page-section-ptb">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="section-title">
            <span className="text-white">
              What Our Happy Clients say about us
            </span>
            <h2 className="text-white">OUR TESTIMONIAL</h2>
            <div className="separator" />
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-8 col-md-12">
          <div
            className="owl-carousel owl-theme"
            data-nav-dots="true"
            data-items={1}
            data-md-items={1}
            data-sm-items={1}
            data-xs-items={1}
            data-space={30}
          >
            <div className="item">
              <div className="testimonial-block">
                <div className="testimonial-content">
                  <i className="fa fa-quote-left" />
                  <p>
                    You will begin to realize why this exercise is called the
                    Dickens reference to the ghost showing Scrooge Pattern with
                    reference to the ghost showing Scrooge some different
                    futures as you notice that the idea of this exercise.
                  </p>
                </div>
                <div className="testimonial-info">
                  <div className="testimonial-avatar">
                    <img
                      className="img-fluid"
                      src="assets/images/team/01.jpg"
                      alt=""
                    />
                  </div>
                  <div className="testimonial-name">
                    <h6 className="text-white">John Doe</h6>
                    <span> |Customer</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="testimonial-block">
                <div className="testimonial-content">
                  <i className="fa fa-quote-left" />
                  <p>
                    You will begin to realize why this exercise is called the
                    Dickens reference to the ghost showing Scrooge Pattern with
                    reference to the ghost showing Scrooge some different
                    futures as you notice that the idea of this exercise.
                  </p>
                </div>
                <div className="testimonial-info">
                  <div className="testimonial-avatar">
                    <img
                      className="img-fluid"
                      src="assets/images/team/02.jpg"
                      alt=""
                    />
                  </div>
                  <div className="testimonial-name">
                    <h6 className="text-white">Alice Williams</h6>
                    <span> |Car Dealer</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="testimonial-block">
                <div className="testimonial-content">
                  <i className="fa fa-quote-left" />
                  <p>
                    {" "}
                    You will begin to realize why this exercise is called the
                    Dickens reference to the ghost showing Scrooge Pattern with
                    reference to the ghost showing Scrooge some different
                    futures as you notice that the idea of this exercise.
                  </p>
                </div>
                <div className="testimonial-info">
                  <div className="testimonial-avatar">
                    <img
                      className="img-fluid"
                      src="assets/images/team/03.jpg"
                      alt=""
                    />
                  </div>
                  <div className="testimonial-name">
                    <h6 className="text-white">Felica Queen</h6>
                    <span> |Auto Dealer</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*=================================
 testimonial */}
  {/*=================================
 our team */}
  <section className="our-team white-bg page-section-ptb">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="section-title">
            <span>List of people who matter in our Company</span>
            <h2>Our Expert Team</h2>
            <div className="separator" />
          </div>
        </div>
      </div>
      <div className="pad-t-30">
        <div className="row">
          <div className="col-md-12">
            <div
              className="owl-carousel owl-theme"
              data-nav-arrow="true"
              data-items={4}
              data-md-items={4}
              data-sm-items={2}
              data-xs-items={2}
              data-xx-items={1}
              data-space={15}
            >
              <div className="item">
                <div className="team text-center">
                  <div className="team-image">
                    <img
                      className="img-fluid icon"
                      src="assets/images/team/01.jpg"
                      alt=""
                    />
                    <div className="team-social">
                      <ul>
                        <li>
                          <a className="icon-1" href="index-6.html#">
                            <i className="fa fa-facebook" />
                          </a>
                        </li>
                        <li>
                          <a className="icon-2" href="index-6.html#">
                            <i className="fa fa-twitter" />
                          </a>
                        </li>
                        <li>
                          <a className="icon-3" href="index-6.html#">
                            <i className="fa fa-pinterest-p" />
                          </a>
                        </li>
                        <li>
                          <a className="icon-4" href="index-6.html#">
                            <i className="fa fa-behance" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="team-name">
                    <h5 className="text-black">John Doe</h5>
                    <span className="text-black">Auto Dealer</span>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="team  text-center">
                  <div className="team-image">
                    <img
                      className="img-fluid icon"
                      src="assets/images/team/02.jpg"
                      alt=""
                    />
                    <div className="team-social">
                      <ul>
                        <li>
                          <a className="icon-1" href="index-6.html#">
                            <i className="fa fa-facebook" />
                          </a>
                        </li>
                        <li>
                          <a className="icon-2" href="index-6.html#">
                            <i className="fa fa-twitter" />
                          </a>
                        </li>
                        <li>
                          <a className="icon-3" href="index-6.html#">
                            <i className="fa fa-pinterest-p" />
                          </a>
                        </li>
                        <li>
                          <a className="icon-4" href="index-6.html#">
                            <i className="fa fa-behance" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="team-name">
                    <h5 className="text-black">Alice Williams</h5>
                    <span className="text-black">Car Dealer</span>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="team  text-center">
                  <div className="team-image">
                    <img
                      className="img-fluid icon"
                      src="assets/images/team/03.jpg"
                      alt=""
                    />
                    <div className="team-social">
                      <ul>
                        <li>
                          <a className="icon-1" href="index-6.html#">
                            <i className="fa fa-facebook" />
                          </a>
                        </li>
                        <li>
                          <a className="icon-2" href="index-6.html#">
                            <i className="fa fa-twitter" />
                          </a>
                        </li>
                        <li>
                          <a className="icon-3" href="index-6.html#">
                            <i className="fa fa-pinterest-p" />
                          </a>
                        </li>
                        <li>
                          <a className="icon-4" href="index-6.html#">
                            <i className="fa fa-behance" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="team-name">
                    <h5 className="text-black">Sara Lisbon</h5>
                    <span className="text-black">Customer</span>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="team  text-center">
                  <div className="team-image">
                    <img
                      className="img-fluid icon"
                      src="assets/images/team/04.jpg"
                      alt=""
                    />
                    <div className="team-social">
                      <ul>
                        <li>
                          <a className="icon-1" href="index-6.html#">
                            <i className="fa fa-facebook" />
                          </a>
                        </li>
                        <li>
                          <a className="icon-2" href="index-6.html#">
                            <i className="fa fa-twitter" />
                          </a>
                        </li>
                        <li>
                          <a className="icon-3" href="index-6.html#">
                            <i className="fa fa-pinterest-p" />
                          </a>
                        </li>
                        <li>
                          <a className="icon-4" href="index-6.html#">
                            <i className="fa fa-behance" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="team-name">
                    <h5 className="text-black">Anne Smith</h5>
                    <span className="text-black">Auto Dealer</span>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="team  text-center">
                  <div className="team-image">
                    <img
                      className="img-fluid icon"
                      src="assets/images/team/05.jpg"
                      alt=""
                    />
                    <div className="team-social">
                      <ul>
                        <li>
                          <a className="icon-1" href="index-6.html#">
                            <i className="fa fa-facebook" />
                          </a>
                        </li>
                        <li>
                          <a className="icon-2" href="index-6.html#">
                            <i className="fa fa-twitter" />
                          </a>
                        </li>
                        <li>
                          <a className="icon-3" href="index-6.html#">
                            <i className="fa fa-pinterest-p" />
                          </a>
                        </li>
                        <li>
                          <a className="icon-4" href="index-6.html#">
                            <i className="fa fa-behance" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="team-name">
                    <h5 className="text-black">Michael Bean</h5>
                    <span className="text-black">Customer</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*=================================
 our team */}
  {/*=================================
 get-quote */}
  <section className="get-quote page-section-ptb bg-5">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="quote-content text-center">
            <i className="fa fa-car" />
            <h2 className="text-white">do you need help in choosing a car? </h2>
            <p className="text-white">
              Call us : <span className="text-red"> (007) 123 456 7890</span>{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</>



    

    );
}

export default Home2;
