//import React from 'react';
// Importation des headers
import Header1 from './headers/Header1';
import Header2 from './headers/Header2';
import HeaderError from './headers/Header-error';
//Importation des footers
import Footer1 from './footers/Footer1';
import Footer2 from './footers/Footer2';
import FooterError from './footers/Footer-error';
//Importation des app
import Home1 from './app/Home1';
import Home2 from './app/Home2';
import About from './app/About';
import Login from './app/login';
import ErrorPage from './ErrorPage';
//Importation des dependanceHead
import Head1 from './dependanceHead/Head1';
import Head2 from './dependanceHead/Head2';
//Importation des dependanceJs
import Module1 from './dependanceJs/ModuleJs1';
import ModuleJs2 from './dependanceJs/ModuleJs2';

// Configuration des routes
const routesConfig = [
  {
    path: '/',
    component: Home2,
    header: Header2,
    footer: Footer2,
    dependanceHead: Head2,
    dependanceJs: ModuleJs2,

  },{
    path: '/accueil',
    component: Home1,
    header: Header1,
    footer: Footer1,
    dependanceHead: Head1,
    dependanceJs:Module1,
  },{
    path: '/apropos',
    component: About,
    header: Header1,
    footer: Footer1,
    dependanceHead: Head1,
    dependanceJs:Module1,
  },{
    path: '/connexion',
    component: Login,
    header: Header2,
    footer: Footer2,
    dependanceHead: Head2,
    dependanceJs:ModuleJs2,
  },
  {
    path: '*',
    component: ErrorPage,
    header: HeaderError,
    footer: FooterError,
    dependanceHead: Head1,
    dependanceJs:Module1,
  },
  

];

export default routesConfig;

