import React from 'react';

function Footer2() {
    
    return (
        <>
  {/* main-footer */}
  <footer className="footer-3 footer-simple">
  <div className="container">
    <div className="row">
      <div className="col-md-4 text-center text-md-start">
        <img
          id="logo-footer"
          className="img-fluid"
          src="vue1/images/logo-light4.png"
          alt=""
        />
      </div>
      <div className="col-md-8 text-md-end text-center mt-4 mt-md-0">
        <div className="social">
          <ul>
            <li>
              <a className="m-0" href="index-car-directory.html#">
                {" "}
                <i className="fa fa-facebook" />{" "}
              </a>
            </li>
            <li>
              <a href="index-car-directory.html#">
                {" "}
                <i className="fa fa-twitter" />{" "}
              </a>
            </li>
            <li>
              <a href="index-car-directory.html#">
                {" "}
                <i className="fa fa-pinterest-p" />{" "}
              </a>
            </li>
            <li>
              <a href="index-car-directory.html#">
                {" "}
                <i className="fa fa-dribbble" />{" "}
              </a>
            </li>
            <li>
              <a href="index-car-directory.html#">
                {" "}
                <i className="fa fa-instagram" />{" "}
              </a>
            </li>
            <li>
              <a href="index-car-directory.html#">
                {" "}
                <i className="fa fa-google-plus" />{" "}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div className="copyright">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <div className="text-lg-start text-center">
            <p className="mb-0">
              ©Copyright 2021 Car Dealer Developed by{" "}
              <a href="http://www.potenzaglobalsolutions.com/">
                Potenzaglobalsolutions
              </a>
            </p>
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <ul className="list-inline text-lg-end text-center">
            <li>
              <a href="index-car-directory.html#">privacy policy </a> |{" "}
            </li>
            <li>
              <a href="index-car-directory.html#">terms and conditions </a> |
            </li>
            <li>
              <a href="index-car-directory.html#">contact us </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>
<div className="car-top">
  <span>
    <img src="vue1/images/car.png" alt="" />
  </span>
</div>

</>

    );
}

export default Footer2;
