import React from 'react';

function HeaderError() {
  return (
    <></>

  );
}

export default HeaderError;
