import React from 'react';
import Loader from '../Loader'
function Home() {
    return (
      <>
        <Loader/>
  <section className="banner-style-three">
    <div className="pattern-layer">
      <div
        className="pattern-2"
        style={{ backgroundImage: "url(assets/images/shape/shape-22.png)" }}
      />
      <div
        className="pattern-1"
        style={{ backgroundImage: "url(assets/images/shape/shape-21.png)" }}
      />
      <div
        className="pattern-3"
        style={{ backgroundImage: "url(assets/images/shape/shape-23.png)" }}
      />
    </div>
    <div className="auto-container">
      <div className="row clearfix">
        <div className="col-lg-6 col-md-12 col-sm-12 content-column">
          <div className="content-box">
            <h6>Welcome to Counsolve</h6>
            <h2>Investment advisory services</h2>
            <p>
              The moment, so blinded by desire, that they cannot foresee and
              trouble that are bound to ensue.{" "}
            </p>
            <div className="support-box">
              <div
                className="shape"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-18.png)"
                }}
              />
              <div className="single-item">
                <div className="icon-box">
                  <i className="flaticon-headphone" />
                </div>
                <h5>
                  Have <br />
                  Question?
                </h5>
              </div>
              <div className="phone">
                <h3>
                  <a href="tel:4188822333">(+41) 888.22.333</a> (or)
                </h3>
              </div>
            </div>
            <div className="btn-box">
              <a href="contact.html" className="theme-btn btn-two">
                Send Your Request
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 image-column">
          <div className="image-box">
            <div className="shape">
              <div
                className="shape-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-19.png)"
                }}
              />
              <div
                className="shape-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-20.png)"
                }}
              />
            </div>
            <figure className="image">
              <img src="assets/images/banner/banner-img-1.jpg" alt="" />
            </figure>
            <div className="growth-box">
              <h5>Monthly Growth</h5>
              <div className="progress-box">
                <h5>$48,560,25</h5>
                <div className="bar">
                  <div className="bar-inner count-bar" data-percent="60%" />
                  <div className="count-text">+18%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ul className="social-links clearfix">
      <li>
        <h5>Social Connect</h5>
      </li>
      <li>
        <a href="index.html">
          <i className="fa-brands fa-facebook" />
        </a>
      </li>
      <li>
        <a href="index.html">
          <i className="fa-brands fa-square-twitter" />
        </a>
      </li>
      <li>
        <a href="index.html">
          <i className="fa-solid fa-basketball" />
        </a>
      </li>
      <li>
        <a href="index.html">
          <i className="fa-brands fa-youtube" />
        </a>
      </li>
    </ul>
  </section>
  {/* banner-section end */}
  {/* service-style-three */}
  <section className="service-style-three">
    <div className="auto-container">
      <div className="sec-title centred light">
        <span className="sub-title">Solutions</span>
        <h2>Growing with Smart Ideas</h2>
      </div>
      <div className="four-item-carousel owl-carousel owl-theme dots-style-one owl-nav-none">
        <div className="service-block-two">
          <div className="inner-box">
            <h3>
              <a href="service-details.html">
                Investmant <br />
                Planning
              </a>
            </h3>
            <figure className="image-box">
              <img src="assets/images/service/service-1.jpg" alt="" />
            </figure>
          </div>
          <div className="link-box">
            <a href="service-details.html">
              <i className="flaticon-right-arrow-1" />
            </a>
          </div>
        </div>
        <div className="service-block-two">
          <div className="inner-box">
            <h3>
              <a href="service-details-2.html">
                Portfolio <br />
                Management
              </a>
            </h3>
            <figure className="image-box">
              <img src="assets/images/service/service-2.jpg" alt="" />
            </figure>
          </div>
          <div className="link-box">
            <a href="service-details-2.html">
              <i className="flaticon-right-arrow-1" />
            </a>
          </div>
        </div>
        <div className="service-block-two">
          <div className="inner-box">
            <h3>
              <a href="service-details-3.html">
                Asset <br />
                Selection
              </a>
            </h3>
            <figure className="image-box">
              <img src="assets/images/service/service-3.jpg" alt="" />
            </figure>
          </div>
          <div className="link-box">
            <a href="service-details-3.html">
              <i className="flaticon-right-arrow-1" />
            </a>
          </div>
        </div>
        <div className="service-block-two">
          <div className="inner-box">
            <h3>
              <a href="service-details-4.html">
                Risk <br />
                Management
              </a>
            </h3>
            <figure className="image-box">
              <img src="assets/images/service/service-4.jpg" alt="" />
            </figure>
          </div>
          <div className="link-box">
            <a href="service-details-4.html">
              <i className="flaticon-right-arrow-1" />
            </a>
          </div>
        </div>
        <div className="service-block-two">
          <div className="inner-box">
            <h3>
              <a href="service-details.html">
                Investmant <br />
                Planning
              </a>
            </h3>
            <figure className="image-box">
              <img src="assets/images/service/service-1.jpg" alt="" />
            </figure>
          </div>
          <div className="link-box">
            <a href="service-details.html">
              <i className="flaticon-right-arrow-1" />
            </a>
          </div>
        </div>
        <div className="service-block-two">
          <div className="inner-box">
            <h3>
              <a href="service-details-2.html">
                Portfolio <br />
                Management
              </a>
            </h3>
            <figure className="image-box">
              <img src="assets/images/service/service-2.jpg" alt="" />
            </figure>
          </div>
          <div className="link-box">
            <a href="service-details-2.html">
              <i className="flaticon-right-arrow-1" />
            </a>
          </div>
        </div>
        <div className="service-block-two">
          <div className="inner-box">
            <h3>
              <a href="service-details-3.html">
                Asset <br />
                Selection
              </a>
            </h3>
            <figure className="image-box">
              <img src="assets/images/service/service-3.jpg" alt="" />
            </figure>
          </div>
          <div className="link-box">
            <a href="service-details-3.html">
              <i className="flaticon-right-arrow-1" />
            </a>
          </div>
        </div>
        <div className="service-block-two">
          <div className="inner-box">
            <h3>
              <a href="service-details-4.html">
                Risk <br />
                Management
              </a>
            </h3>
            <figure className="image-box">
              <img src="assets/images/service/service-4.jpg" alt="" />
            </figure>
          </div>
          <div className="link-box">
            <a href="service-details-4.html">
              <i className="flaticon-right-arrow-1" />
            </a>
          </div>
        </div>
        <div className="service-block-two">
          <div className="inner-box">
            <h3>
              <a href="service-details.html">
                Investmant <br />
                Planning
              </a>
            </h3>
            <figure className="image-box">
              <img src="assets/images/service/service-1.jpg" alt="" />
            </figure>
          </div>
          <div className="link-box">
            <a href="service-details.html">
              <i className="flaticon-right-arrow-1" />
            </a>
          </div>
        </div>
        <div className="service-block-two">
          <div className="inner-box">
            <h3>
              <a href="service-details-2.html">
                Portfolio <br />
                Management
              </a>
            </h3>
            <figure className="image-box">
              <img src="assets/images/service/service-2.jpg" alt="" />
            </figure>
          </div>
          <div className="link-box">
            <a href="service-details-2.html">
              <i className="flaticon-right-arrow-1" />
            </a>
          </div>
        </div>
        <div className="service-block-two">
          <div className="inner-box">
            <h3>
              <a href="service-details-3.html">
                Asset <br />
                Selection
              </a>
            </h3>
            <figure className="image-box">
              <img src="assets/images/service/service-3.jpg" alt="" />
            </figure>
          </div>
          <div className="link-box">
            <a href="service-details-3.html">
              <i className="flaticon-right-arrow-1" />
            </a>
          </div>
        </div>
        <div className="service-block-two">
          <div className="inner-box">
            <h3>
              <a href="service-details-4.html">
                Risk <br />
                Management
              </a>
            </h3>
            <figure className="image-box">
              <img src="assets/images/service/service-4.jpg" alt="" />
            </figure>
          </div>
          <div className="link-box">
            <a href="service-details-4.html">
              <i className="flaticon-right-arrow-1" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* service-style-three end */}
  {/* about-style-three */}
  <section className="about-style-three sec-pad">
    <div className="auto-container">
      <div className="row clearfix">
        <div className="col-lg-7 col-md-12 col-sm-12 image-column">
          <div className="image-box">
            <div
              className="image-shape"
              style={{
                backgroundImage: "url(assets/images/shape/shape-24.png)"
              }}
            />
            <figure className="image image-1">
              <img src="assets/images/resource/about-2.jpg" alt="" />
            </figure>
            <figure className="image image-2">
              <img src="assets/images/resource/about-3.jpg" alt="" />
            </figure>
            <div className="image-content">
              <h6>Feb’2023</h6>
              <div className="icon-box">
                <i className="flaticon-diagonal-arrow" />
              </div>
              <h2>
                6.5<span>Million</span>
              </h2>
              <p>Customers Benefits</p>
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-md-12 col-sm-12 content-column">
          <div className="content-box">
            <div className="sec-title">
              <span className="sub-title">About Us</span>
              <h2>Experts in Providing Investment Consulting</h2>
            </div>
            <div className="text-box">
              <p>
                Perfectly simple &amp; easy to distinguish. In a free hour all,
                when our power of choice is untrammelled &amp; when nothing
                prevents our being able to what we like best, every pleasure is
                to be welcomed &amp; every pain avoided but in certain have to
                repudiated all annoyances accepted.
              </p>
            </div>
            <div className="single-item-carousel owl-carousel owl-dots-none">
              <div className="inner-box">
                <div className="icon-box">
                  <div className="icon">
                    <i className="flaticon-knowledge" />
                  </div>
                  <span className="count-text">01</span>
                </div>
                <h3>Extensive Knowledge</h3>
                <p>
                  Foresee the pain trouble all that rationally encounter
                  undertakes.
                </p>
              </div>
              <div className="inner-box">
                <div className="icon-box">
                  <div className="icon">
                    <i className="flaticon-knowledge" />
                  </div>
                  <span className="count-text">02</span>
                </div>
                <h3>Extensive Knowledge</h3>
                <p>
                  Foresee the pain trouble all that rationally encounter
                  undertakes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* about-style-three end */}
  {/* funfact-style-two */}
  <section className="funfact-style-two">
    <div className="auto-container">
      <div className="title-box">
        <h6>Numbers&nbsp;speak&nbsp;for&nbsp;themselves</h6>
        <div className="line" />
      </div>
      <div className="row clearfix">
        <div className="col-lg-4 col-md-6 col-sm-12 funfact-block">
          <div className="funfact-block-two">
            <div className="inner-box">
              <div className="icon-box">
                <img src="assets/images/icons/icon-21.png" alt="" />
              </div>
              <div className="count-outer count-box counted">
                <span className="count-text" data-speed={1500} data-stop={840}>
                  840
                </span>
                <span className="text">Billion</span>
              </div>
              <p>Client assets under advisement.</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 funfact-block">
          <div className="funfact-block-two">
            <div className="inner-box">
              <div className="icon-box">
                <img src="assets/images/icons/icon-22.png" alt="" />
              </div>
              <div className="count-outer count-box counted">
                <span className="count-text" data-speed={1500} data-stop={93}>
                  93
                </span>
                <span className="text">Locations</span>
              </div>
              <p>Service with professional firm</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 funfact-block">
          <div className="funfact-block-two">
            <div className="inner-box">
              <div className="icon-box">
                <img src="assets/images/icons/icon-23.png" alt="" />
              </div>
              <div className="count-outer count-box counted">
                <span className="count-text" data-speed={1500} data-stop={840}>
                  40+
                </span>
                <span className="text">Years</span>
              </div>
              <p>Experience in consulting fileld</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* funfact-style-two end */}
  {/* cta-section */}
  <section className="cta-section">
    <div className="outer-container">
      <div
        className="pattern-layer"
        style={{ backgroundImage: "url(assets/images/shape/shape-25.png)" }}
      />
      <div className="image-box-one">
        <figure className="image">
          <img src="assets/images/resource/cta-1.jpg" alt="" />
        </figure>
        <div className="phone">
          <h4>
            Call: <a href="tel:41888765432">(+41) 888.76.5432</a>
          </h4>
        </div>
      </div>
      <div className="image-box-two">
        <figure className="image">
          <img src="assets/images/resource/cta-2.jpg" alt="" />
        </figure>
        <div className="text-box">
          <h6>
            Do you Have <br />
            any idea to Join <br />
            With Us
          </h6>
        </div>
      </div>
      <div className="auto-container">
        <div className="row clearfix">
          <div className="col-lg-8 col-md-12 col-sm-12 offset-lg-2 content-column">
            <div className="content-box">
              <h2>
                Believe us when <br />
                it comes to investment
              </h2>
              <div className="inner-box">
                <figure className="image-box">
                  <img src="assets/images/resource/cart-1.jpg" alt="" />
                </figure>
                <p>
                  The moment, so blinded by desire, that they cannot foresee and
                  trouble that are bound to ensue.
                </p>
                <a href="index-3.html" className="theme-btn btn-two">
                  Send Request
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* cta-section end */}
  {/* team-section */}
  <section className="team-section sec-pad">
    <div className="auto-container">
      <div className="sec-title">
        <span className="sub-title">Leadership</span>
        <h2>Team Behind Company</h2>
      </div>
      <div className="three-item-carousel owl-carousel owl-theme">
        <div className="team-block-one">
          <div className="inner-box">
            <figure className="image-box">
              <img src="assets/images/team/team-1.jpg" alt="" />
            </figure>
            <div className="lower-content">
              <div className="share-box">
                <div className="share-icon">
                  <i className="flaticon-share" />
                </div>
                <ul className="social-links clearfix">
                  <li>
                    <a href="index.html">
                      <i className="fa-brands fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="index.html">
                      <i className="fa-brands fa-square-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="index.html">
                      <i className="fa-brands fa-instagram-square" />
                    </a>
                  </li>
                </ul>
              </div>
              <h3>
                <a href="index-3.html">Bertram Irvin</a>
              </h3>
              <p>
                He is Chairman and Chief Executive Officer of Counsolve.{" "}
                <a href="index-3.html">Read...</a>
              </p>
            </div>
          </div>
        </div>
        <div className="team-block-one">
          <div className="inner-box">
            <figure className="image-box">
              <img src="assets/images/team/team-2.jpg" alt="" />
            </figure>
            <div className="lower-content">
              <div className="share-box">
                <div className="share-icon">
                  <i className="flaticon-share" />
                </div>
                <ul className="social-links clearfix">
                  <li>
                    <a href="index.html">
                      <i className="fa-brands fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="index.html">
                      <i className="fa-brands fa-square-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="index.html">
                      <i className="fa-brands fa-instagram-square" />
                    </a>
                  </li>
                </ul>
              </div>
              <h3>
                <a href="index-3.html">Marian Lenora</a>
              </h3>
              <p>
                She is Chief Investment Officer of ETF in Counsolve from 2021.{" "}
                <a href="index-3.html">Read...</a>
              </p>
            </div>
          </div>
        </div>
        <div className="team-block-one">
          <div className="inner-box">
            <figure className="image-box">
              <img src="assets/images/team/team-3.jpg" alt="" />
            </figure>
            <div className="lower-content">
              <div className="share-box">
                <div className="share-icon">
                  <i className="flaticon-share" />
                </div>
                <ul className="social-links clearfix">
                  <li>
                    <a href="index.html">
                      <i className="fa-brands fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="index.html">
                      <i className="fa-brands fa-square-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="index.html">
                      <i className="fa-brands fa-instagram-square" />
                    </a>
                  </li>
                </ul>
              </div>
              <h3>
                <a href="index-3.html">Herman Gordon</a>
              </h3>
              <p>
                He is Senior Retirement Plan Consultant of Counsolve.{" "}
                <a href="index-3.html">Read...</a>
              </p>
            </div>
          </div>
        </div>
        <div className="team-block-one">
          <div className="inner-box">
            <figure className="image-box">
              <img src="assets/images/team/team-1.jpg" alt="" />
            </figure>
            <div className="lower-content">
              <div className="share-box">
                <div className="share-icon">
                  <i className="flaticon-share" />
                </div>
                <ul className="social-links clearfix">
                  <li>
                    <a href="index.html">
                      <i className="fa-brands fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="index.html">
                      <i className="fa-brands fa-square-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="index.html">
                      <i className="fa-brands fa-instagram-square" />
                    </a>
                  </li>
                </ul>
              </div>
              <h3>
                <a href="index-3.html">Bertram Irvin</a>
              </h3>
              <p>
                He is Chairman and Chief Executive Officer of Counsolve.{" "}
                <a href="index-3.html">Read...</a>
              </p>
            </div>
          </div>
        </div>
        <div className="team-block-one">
          <div className="inner-box">
            <figure className="image-box">
              <img src="assets/images/team/team-2.jpg" alt="" />
            </figure>
            <div className="lower-content">
              <div className="share-box">
                <div className="share-icon">
                  <i className="flaticon-share" />
                </div>
                <ul className="social-links clearfix">
                  <li>
                    <a href="index.html">
                      <i className="fa-brands fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="index.html">
                      <i className="fa-brands fa-square-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="index.html">
                      <i className="fa-brands fa-instagram-square" />
                    </a>
                  </li>
                </ul>
              </div>
              <h3>
                <a href="index-3.html">Marian Lenora</a>
              </h3>
              <p>
                She is Chief Investment Officer of ETF in Counsolve from 2021.{" "}
                <a href="index-3.html">Read...</a>
              </p>
            </div>
          </div>
        </div>
        <div className="team-block-one">
          <div className="inner-box">
            <figure className="image-box">
              <img src="assets/images/team/team-3.jpg" alt="" />
            </figure>
            <div className="lower-content">
              <div className="share-box">
                <div className="share-icon">
                  <i className="flaticon-share" />
                </div>
                <ul className="social-links clearfix">
                  <li>
                    <a href="index.html">
                      <i className="fa-brands fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="index.html">
                      <i className="fa-brands fa-square-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="index.html">
                      <i className="fa-brands fa-instagram-square" />
                    </a>
                  </li>
                </ul>
              </div>
              <h3>
                <a href="index-3.html">Herman Gordon</a>
              </h3>
              <p>
                He is Senior Retirement Plan Consultant of Counsolve.{" "}
                <a href="index-3.html">Read...</a>
              </p>
            </div>
          </div>
        </div>
        <div className="team-block-one">
          <div className="inner-box">
            <figure className="image-box">
              <img src="assets/images/team/team-1.jpg" alt="" />
            </figure>
            <div className="lower-content">
              <div className="share-box">
                <div className="share-icon">
                  <i className="flaticon-share" />
                </div>
                <ul className="social-links clearfix">
                  <li>
                    <a href="index.html">
                      <i className="fa-brands fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="index.html">
                      <i className="fa-brands fa-square-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="index.html">
                      <i className="fa-brands fa-instagram-square" />
                    </a>
                  </li>
                </ul>
              </div>
              <h3>
                <a href="index-3.html">Bertram Irvin</a>
              </h3>
              <p>
                He is Chairman and Chief Executive Officer of Counsolve.{" "}
                <a href="index-3.html">Read...</a>
              </p>
            </div>
          </div>
        </div>
        <div className="team-block-one">
          <div className="inner-box">
            <figure className="image-box">
              <img src="assets/images/team/team-2.jpg" alt="" />
            </figure>
            <div className="lower-content">
              <div className="share-box">
                <div className="share-icon">
                  <i className="flaticon-share" />
                </div>
                <ul className="social-links clearfix">
                  <li>
                    <a href="index.html">
                      <i className="fa-brands fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="index.html">
                      <i className="fa-brands fa-square-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="index.html">
                      <i className="fa-brands fa-instagram-square" />
                    </a>
                  </li>
                </ul>
              </div>
              <h3>
                <a href="index-3.html">Marian Lenora</a>
              </h3>
              <p>
                She is Chief Investment Officer of ETF in Counsolve from 2021.{" "}
                <a href="index-3.html">Read...</a>
              </p>
            </div>
          </div>
        </div>
        <div className="team-block-one">
          <div className="inner-box">
            <figure className="image-box">
              <img src="assets/images/team/team-3.jpg" alt="" />
            </figure>
            <div className="lower-content">
              <div className="share-box">
                <div className="share-icon">
                  <i className="flaticon-share" />
                </div>
                <ul className="social-links clearfix">
                  <li>
                    <a href="index.html">
                      <i className="fa-brands fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="index.html">
                      <i className="fa-brands fa-square-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="index.html">
                      <i className="fa-brands fa-instagram-square" />
                    </a>
                  </li>
                </ul>
              </div>
              <h3>
                <a href="index-3.html">Herman Gordon</a>
              </h3>
              <p>
                He is Senior Retirement Plan Consultant of Counsolve.{" "}
                <a href="index-3.html">Read...</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="more-text centred">
        <h5>
          <a href="team.html">
            View All Members
            <i className="flaticon-right-chevron" />
          </a>
        </h5>
      </div>
    </div>
  </section>
  {/* team-section end */}
  {/* chooseus-style-three */}
  <section className="chooseus-style-three">
    <div
      className="pattern-layer"
      style={{ backgroundImage: "url(assets/images/shape/shape-27.png)" }}
    />
    <div className="auto-container">
      <div className="sec-title centred">
        <span className="sub-title">Why Coose Us</span>
        <h2>Reason for Choosee Counsolve</h2>
      </div>
      <div className="row clearfix">
        <div className="col-lg-4 col-md-6 col-sm-12 chooseus-block">
          <div
            className="chooseus-block-three wow fadeInLeft animated"
            data-wow-delay="00ms"
            data-wow-duration="1500ms"
          >
            <div className="inner-box">
              <span className="count-text">01</span>
              <h3>
                Extensive <span>Knowledge</span>
              </h3>
              <p>
                Prevents our being able to do what welike best,every
                circumstances less owing to the claims.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 chooseus-block">
          <div
            className="chooseus-block-three wow fadeInUp animated"
            data-wow-delay="00ms"
            data-wow-duration="1500ms"
          >
            <div className="inner-box">
              <span className="count-text">02</span>
              <h3>
                Risk <span>Management</span>
              </h3>
              <p>
                Undertakes laborious physical except to obtain some advantage
                from it right to find fault.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 chooseus-block">
          <div
            className="chooseus-block-three wow fadeInRight animated"
            data-wow-delay="00ms"
            data-wow-duration="1500ms"
          >
            <div className="inner-box">
              <span className="count-text">03</span>
              <h3>
                Team <span>Approach</span>
              </h3>
              <p>
                Cases are perfectly simple and easy to distinguish. In a free
                hour, when all choice is untrammelled hold.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 chooseus-block">
          <div
            className="chooseus-block-three wow fadeInLeft animated"
            data-wow-delay="300ms"
            data-wow-duration="1500ms"
          >
            <div className="inner-box">
              <span className="count-text">04</span>
              <h3>
                Advanced <span>Technolog</span>
              </h3>
              <p>
                Take a trivial example, which of ever undertakes laborious
                physical obtain some advantages.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 chooseus-block">
          <div
            className="chooseus-block-three wow fadeInUp animated"
            data-wow-delay="300ms"
            data-wow-duration="1500ms"
          >
            <div className="inner-box">
              <span className="count-text">05</span>
              <h3>
                Time <span>Savings</span>
              </h3>
              <p>
                Prevents our being able to do what welike best,every
                circumstances less owing to the claims.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 chooseus-block">
          <div
            className="chooseus-block-three wow fadeInRight animated"
            data-wow-delay="300ms"
            data-wow-duration="1500ms"
          >
            <div className="inner-box">
              <span className="count-text">06</span>
              <h3>
                Customized <span>Advice</span>
              </h3>
              <p>
                Undertakes laborious physical except to obtain some advantage
                from it right to find fault.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* chooseus-style-three end */}
  {/* pricing-style-two */}
  <section className="pricing-style-two sec-pad">
    <div className="auto-container">
      <div className="row clearfix">
        <div className="col-lg-4 col-md-12 col-sm-12 content-column">
          <div className="content-box">
            <div className="sec-title">
              <span className="sub-title">Plan &amp; Pricing</span>
              <h2>Effective &amp; Flexible Pricing</h2>
            </div>
            <div className="text-box">
              <p>
                Welcomed and every pain avoided but in certain circumstances.
              </p>
              <ul className="list-item clearfix">
                <li>Investment Strategy</li>
                <li>Investment Manager Selection</li>
              </ul>
              <h3>Get help to Choose Right Plan</h3>
            </div>
            <div className="author-box">
              <figure className="author-thumb">
                <img src="assets/images/resource/author-1.png" alt="" />
              </figure>
              <h3>Roman Harry</h3>
              <span className="designation">Advisor</span>
              <h4>
                <i className="flaticon-whatsapp" />
                <a href="tel:15557890123">+1 (555) 789.0123</a>
              </h4>
            </div>
          </div>
        </div>
        <div className="col-lg-8 col-md-12 col-sm-12 inner-column">
          <div className="inner-content">
            <div className="row clearfix">
              <div className="col-lg-6 col-md-6 col-sm-12 pricing-block">
                <div className="pricing-block-one">
                  <div className="pricing-table">
                    <div className="table-header">
                      <div className="icon-box">
                        <i className="flaticon-idea" />
                      </div>
                      <h3>
                        Basic <br />
                        Package
                      </h3>
                      <p>Pricing plan for small business</p>
                    </div>
                    <div className="table-content">
                      <ul className="feature-list clearfix">
                        <li>Traditional Consulting</li>
                        <li>Investment Management</li>
                        <li>Data Aggregation</li>
                        <li className="light">
                          Tax Planning &amp; Preparation
                        </li>
                      </ul>
                      <h2>
                        49 <span className="symble">$</span>
                        <span className="fraction">.99</span>
                        <span className="text">Billed Monthly</span>
                      </h2>
                      <a href="index-2.html" className="theme-btn btn-two">
                        Get Started Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pricing-block">
                <div className="pricing-block-one active-block">
                  <div className="pricing-table">
                    <span className="discount-text">
                      10% Discount, Start Today
                    </span>
                    <div className="table-header">
                      <div className="icon-box">
                        <i className="flaticon-star" />
                      </div>
                      <h3>
                        Basic <br />
                        Package
                      </h3>
                      <p>Pricing plan for small business</p>
                    </div>
                    <div className="table-content">
                      <ul className="feature-list clearfix">
                        <li>Traditional Consulting</li>
                        <li>Investment Management</li>
                        <li>Data Aggregation</li>
                        <li>Tax Planning &amp; Preparation</li>
                      </ul>
                      <h2>
                        129 <span className="symble">$</span>
                        <span className="fraction">.99</span>
                        <span className="text">Billed Monthly</span>
                      </h2>
                      <a href="index-2.html" className="theme-btn btn-two">
                        Get Started Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* pricing-style-two end */}
  {/* subscribe-section */}
  <section className="subscribe-section alternat-2">
    <div className="auto-container">
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12 col-sm-12 content-column">
          <div className="content-box">
            <div
              className="shape-2"
              style={{
                backgroundImage: "url(assets/images/shape/shape-29.png)"
              }}
            />
            <div className="icon-box">
              <i className="flaticon-newsletter" />
            </div>
            <span className="big-text">Newsletter</span>
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 col-sm-12 text-column">
                <div className="text-box">
                  <div
                    className="shape"
                    style={{
                      backgroundImage: "url(assets/images/shape/shape-17.png)"
                    }}
                  />
                  <h2>Subscribe Us</h2>
                  <p>Subscribe us to receive market updates.</p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 form-column">
                <div className="form-inner">
                  <form method="post" action="contact.html">
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        placeholder="Email Address..."
                        required=""
                      />
                      <button type="submit">
                        <i className="flaticon-send" />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* subscribe-section end */}
  {/* testimonial-style-three */}
  <section className="testimonial-style-three sec-pad">
    <span className="big-text">feedback</span>
    <div className="auto-container">
      <div className="row clearfix">
        <div className="col-lg-6 col-md-12 col-sm-12 image-column">
          <div className="image-box">
            <figure className="image">
              <img src="assets/images/resource/testimonial-2.jpg" alt="" />
            </figure>
            <div className="text-box">
              <div className="icon-box">
                <i className="flaticon-cartoon" />
              </div>
              <h5>
                Avg.Rating <span>4.8/5</span> <br />
                Based on 2,500 Client <br />
                Reviews.
              </h5>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 content-column">
          <div className="content-box">
            <div className="sec-title">
              <span className="sub-title">Testimonials</span>
              <h2>Clients about our Service</h2>
            </div>
            <div className="single-item-carousel owl-carousel owl-theme">
              <div className="testimonial-content">
                <div className="testimonial-block-two">
                  <div className="inner-box">
                    <div className="thumb-box">
                      <figure className="thumb">
                        <img
                          src="assets/images/resource/testimonial-2.png"
                          alt=""
                        />
                      </figure>
                      <div className="icon-box">
                        <i className="flaticon-quote" />
                      </div>
                    </div>
                    <ul className="rating clearfix">
                      <li>
                        <i className="flaticon-star-1" />
                      </li>
                      <li>
                        <i className="flaticon-star-1" />
                      </li>
                      <li>
                        <i className="flaticon-star-1" />
                      </li>
                      <li>
                        <i className="flaticon-star-1" />
                      </li>
                      <li>
                        <i className="flaticon-star-1" />
                      </li>
                    </ul>
                    <p>
                      I have been financially advised by counsolve investment
                      Planners since 2022 I have had a very good experience. The
                      sound financial advice they gave me helped me to achieve.
                    </p>
                    <h3>Nathan Felix</h3>
                    <h5>Director - Naxly Info tech</h5>
                  </div>
                </div>
                <div className="testimonial-block-two">
                  <div className="inner-box">
                    <div className="thumb-box">
                      <figure className="thumb">
                        <img
                          src="assets/images/resource/testimonial-3.png"
                          alt=""
                        />
                      </figure>
                      <div className="icon-box">
                        <i className="flaticon-quote" />
                      </div>
                    </div>
                    <ul className="rating clearfix">
                      <li>
                        <i className="flaticon-star-1" />
                      </li>
                      <li>
                        <i className="flaticon-star-1" />
                      </li>
                      <li>
                        <i className="flaticon-star-1" />
                      </li>
                      <li>
                        <i className="flaticon-star-1" />
                      </li>
                      <li>
                        <i className="flaticon-half-star" />
                      </li>
                    </ul>
                    <p>
                      Pleasure of the moment, so blinded by desire, that they
                      cannot foresee the pain and trouble that are bound to
                      ensue and equal blame.
                    </p>
                    <h3>Nora Penelope</h3>
                    <h5>Manager - Intexture Interiors</h5>
                  </div>
                </div>
              </div>
              <div className="testimonial-content">
                <div className="testimonial-block-two">
                  <div className="inner-box">
                    <div className="thumb-box">
                      <figure className="thumb">
                        <img
                          src="assets/images/resource/testimonial-2.png"
                          alt=""
                        />
                      </figure>
                      <div className="icon-box">
                        <i className="flaticon-quote" />
                      </div>
                    </div>
                    <ul className="rating clearfix">
                      <li>
                        <i className="flaticon-star-1" />
                      </li>
                      <li>
                        <i className="flaticon-star-1" />
                      </li>
                      <li>
                        <i className="flaticon-star-1" />
                      </li>
                      <li>
                        <i className="flaticon-star-1" />
                      </li>
                      <li>
                        <i className="flaticon-star-1" />
                      </li>
                    </ul>
                    <p>
                      I have been financially advised by counsolve investment
                      Planners since 2022 I have had a very good experience. The
                      sound financial advice they gave me helped me to achieve.
                    </p>
                    <h3>Nathan Felix</h3>
                    <h5>Director - Naxly Info tech</h5>
                  </div>
                </div>
                <div className="testimonial-block-two">
                  <div className="inner-box">
                    <div className="thumb-box">
                      <figure className="thumb">
                        <img
                          src="assets/images/resource/testimonial-3.png"
                          alt=""
                        />
                      </figure>
                      <div className="icon-box">
                        <i className="flaticon-quote" />
                      </div>
                    </div>
                    <ul className="rating clearfix">
                      <li>
                        <i className="flaticon-star-1" />
                      </li>
                      <li>
                        <i className="flaticon-star-1" />
                      </li>
                      <li>
                        <i className="flaticon-star-1" />
                      </li>
                      <li>
                        <i className="flaticon-star-1" />
                      </li>
                      <li>
                        <i className="flaticon-half-star" />
                      </li>
                    </ul>
                    <p>
                      Pleasure of the moment, so blinded by desire, that they
                      cannot foresee the pain and trouble that are bound to
                      ensue and equal blame.
                    </p>
                    <h3>Nora Penelope</h3>
                    <h5>Manager - Intexture Interiors</h5>
                  </div>
                </div>
              </div>
              <div className="testimonial-content">
                <div className="testimonial-block-two">
                  <div className="inner-box">
                    <div className="thumb-box">
                      <figure className="thumb">
                        <img
                          src="assets/images/resource/testimonial-2.png"
                          alt=""
                        />
                      </figure>
                      <div className="icon-box">
                        <i className="flaticon-quote" />
                      </div>
                    </div>
                    <ul className="rating clearfix">
                      <li>
                        <i className="flaticon-star-1" />
                      </li>
                      <li>
                        <i className="flaticon-star-1" />
                      </li>
                      <li>
                        <i className="flaticon-star-1" />
                      </li>
                      <li>
                        <i className="flaticon-star-1" />
                      </li>
                      <li>
                        <i className="flaticon-star-1" />
                      </li>
                    </ul>
                    <p>
                      I have been financially advised by counsolve investment
                      Planners since 2022 I have had a very good experience. The
                      sound financial advice they gave me helped me to achieve.
                    </p>
                    <h3>Nathan Felix</h3>
                    <h5>Director - Naxly Info tech</h5>
                  </div>
                </div>
                <div className="testimonial-block-two">
                  <div className="inner-box">
                    <div className="thumb-box">
                      <figure className="thumb">
                        <img
                          src="assets/images/resource/testimonial-3.png"
                          alt=""
                        />
                      </figure>
                      <div className="icon-box">
                        <i className="flaticon-quote" />
                      </div>
                    </div>
                    <ul className="rating clearfix">
                      <li>
                        <i className="flaticon-star-1" />
                      </li>
                      <li>
                        <i className="flaticon-star-1" />
                      </li>
                      <li>
                        <i className="flaticon-star-1" />
                      </li>
                      <li>
                        <i className="flaticon-star-1" />
                      </li>
                      <li>
                        <i className="flaticon-half-star" />
                      </li>
                    </ul>
                    <p>
                      Pleasure of the moment, so blinded by desire, that they
                      cannot foresee the pain and trouble that are bound to
                      ensue and equal blame.
                    </p>
                    <h3>Nora Penelope</h3>
                    <h5>Manager - Intexture Interiors</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* testimonial-style-three end */}
  {/* clients-style-three */}
  <section className="clients-style-three centred">
    <div className="outer-container">
      <ul className="clients-logo-list">
        <li>
          <figure className="clients-logo">
            <a href="index-3.html">
              <img src="assets/images/clients/clients-1.png" alt="" />
            </a>
          </figure>
        </li>
        <li>
          <figure className="clients-logo">
            <a href="index-3.html">
              <img src="assets/images/clients/clients-2.png" alt="" />
            </a>
          </figure>
        </li>
        <li>
          <figure className="clients-logo">
            <a href="index-3.html">
              <img src="assets/images/clients/clients-3.png" alt="" />
            </a>
          </figure>
        </li>
        <li>
          <figure className="clients-logo">
            <a href="index-3.html">
              <img src="assets/images/clients/clients-4.png" alt="" />
            </a>
          </figure>
        </li>
        <li>
          <figure className="clients-logo">
            <a href="index-3.html">
              <img src="assets/images/clients/clients-5.png" alt="" />
            </a>
          </figure>
        </li>
        <li>
          <figure className="clients-logo">
            <a href="index-3.html">
              <img src="assets/images/clients/clients-8.png" alt="" />
            </a>
          </figure>
        </li>
      </ul>
      <div className="more-text">
        <h5>
          2.6k Companies &amp; Individuals Trusted Us.{" "}
          <a href="index.html">
            <i className="flaticon-right-chevron" />
            View All Clients
          </a>
        </h5>
      </div>
    </div>
  </section>
  {/* clients-style-three end */}
  {/* news-style-three */}
  <section className="news-style-three sec-pad">
    <div className="auto-container">
      <div className="row clearfix">
        <div className="col-lg-4 col-md-12 col-sm-12 title-column">
          <div className="sec-title">
            <span className="sub-title">Blog Post</span>
            <h2>Recent from our News Room</h2>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable.
            </p>
            <a href="blog-2.html" className="theme-btn btn-two">
              Read All Post
            </a>
          </div>
        </div>
        <div className="col-lg-8 col-md-12 col-sm-12 content-column">
          <div className="content-inner">
            <div className="news-block-two">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <a href="blog-details.html">
                      <img src="assets/images/news/news-4.jpg" alt="" />
                    </a>
                  </figure>
                  <h6>Investment</h6>
                </div>
                <div className="content-box">
                  <ul className="post-info clearfix">
                    <li>
                      <span>On</span> Mar 14, 2023
                    </li>
                    <li>
                      <span>By</span>{" "}
                      <a href="blog-details.html">Justin Langer</a>
                    </li>
                  </ul>
                  <h3>
                    <a href="blog-details.html">
                      Revisiting Your Investment &amp; Distribution Goals
                    </a>
                  </h3>
                  <p>
                    Power choice untrammelled when nothing prevent to do what we
                    like best.
                  </p>
                  <div className="link">
                    <a href="blog-details.html">
                      <span>Explore More</span>
                    </a>
                  </div>
                  <div className="share-box">
                    <a href="blog-details.html">
                      <i className="flaticon-share" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="news-block-two">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <a href="blog-details.html">
                      <img src="assets/images/news/news-5.jpg" alt="" />
                    </a>
                  </figure>
                  <h6>Business</h6>
                </div>
                <div className="content-box">
                  <ul className="post-info clearfix">
                    <li>
                      <span>On</span> Feb 26, 2023
                    </li>
                    <li>
                      <span>By</span>{" "}
                      <a href="blog-details.html">Colmin Neil</a>
                    </li>
                  </ul>
                  <h3>
                    <a href="blog-details.html">
                      Dimensional Fund Advisors Interview with Director
                    </a>
                  </h3>
                  <p>
                    Able to do what we like best, every pleasure is to be
                    welcomed and every pain avoided.
                  </p>
                  <div className="link">
                    <a href="blog-details.html">
                      <span>Explore More</span>
                    </a>
                  </div>
                  <div className="share-box">
                    <a href="blog-details.html">
                      <i className="flaticon-share" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* news-style-three end */}
</>

    

    );
}

export default Home;
