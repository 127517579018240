import React from 'react';

function Loader() {
    return (
        <div id="loading">
  <div id="loading-center">
    <img src="vue1/images/loader3.gif" alt="" />
  </div>
</div>

    );
}

export default Loader;
