import React from 'react';
const menuItems = [
  {
    label: 'Accueil',
    href: '/accueil'
  },
  {
    label: 'Investissements',
    href: '/investissements',
    children: [
      { label: 'Catalogue des Véhicules', href: 'catalogue-vehicules.html' },
      { label: 'Camions', href: 'camions.html' },
      { label: 'Tricycles', href: 'tricycles.html' },
      { label: 'Équipements de BTP', href: 'equipements-btp.html' }
      // Other submenus if necessary...
    ],
  },
  {
    label: 'Simulateur',
    href: '/simulateur'
  },
  {
    label: 'À Propos',
    href: '/apropos'
  },
  {
    label: 'FAQ',
    href: '/faq'
  },
  {
    label: 'Contact',
    href: '/contact'
  }
  // Other menu items if necessary...
];
// Fonction TopBar
function TopBar() {
  return (
    <div className="topbar">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-5 col-md-12">
            <div className="topbar-left text-lg-start text-center">
              <ul className="list-inline">
                <li>
                  {" "}
                  <i className="fa fa-envelope-o"> </i> contact@autopartageinvest.com
                </li>
                <li>
                  {" "}
                  <i className="fa fa-clock-o" /> Lun - Ven 8h00 - 18h00. Dim
                  Fermé
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-7 col-md-12 text-lg-end text-center">
            <div className="topbar-profile">
              <ul className="list-inline">
                <li>
                  {" "}
                  <a href="/connexion">
                    <i className="fa fa-user" /> Mon compte
                  </a>{" "}
                  |{" "}
                </li>
                <li>
                  {" "}
                  <a href="/inscription'">
                    <i className="fa fa-check-square-o" /> S'inscrire
                  </a>
                </li>
              </ul>
            </div>
            <div className="topbar-right">
              <ul className="list-inline">
                <li>
                  {" "}
                  <i className="fa fa-phone" /> (00229) 123 456 7890
                </li>
                <li>
                  <a href="index-car-directory.html#">
                    <i className="fa fa-facebook" />
                  </a>
                </li>
                <li>
                  <a href="index-car-directory.html#">
                    <i className="fa fa-instagram" />
                  </a>
                </li>
                <li>
                  <a href="index-car-directory.html#">
                    <i className="fa fa-youtube-play" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// Fonction MenuItem
function MenuItem({ label, href, children }) {
  return (
    <li className={`dropdown ${children ? "has-submenu" : ""}`}>
      <a href={href}>{label}</a>
      {children && (
        <ul className="drop-down-multilevel">
          {children.map((child, index) => (
            <MenuItem key={index} {...child} />
          ))}
        </ul>
      )}
    </li>
  );
}
function ElementMenu() {
  return (
    <>
      {menuItems.map((item, index) => (
        <MenuItem key={index} {...item} />
      ))}
    </>
  );
  
}
// Fonction Menu
function Menu() {
  return (
    <div className="menu">
    <nav id="menu" className="mega-menu">
      {/* menu list items container */}
      <section className="menu-list-items">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              {/* menu logo */}
              <ul className="menu-logo">
                  <li>
                      <a href="/"><img id="logo_img" src="../vue1/images/logo-light4.png" alt="logo" /></a>
                  </li>
              </ul>
              {/* menu links */}
              <ul className="menu-links">
                  {/* Ici, insérez dynamiquement vos MenuItem */}
                  {menuItems.map((item, index) => (
                    <MenuItem key={index} {...item} />
                  ))}
              </ul>
              
            </div>
          </div>
        </div>
      </section>
    </nav>
  </div>
  );
}

// Fonction Header
function Header2() {
  return (
    <header id="header" className="topbar-dark header-dark">
      <TopBar />
      <Menu />
      {/* Autres éléments de l'en-tête ici */}
    </header>
  );
}


export default Header2;
export { ElementMenu };