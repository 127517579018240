import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Footer from './footers/Foote'; // Assurez-vous que le chemin est correct
import AppMain from './App';
import Header from './headers/Header'; // Assurez-vous que le chemin est correct
import reportWebVitals from './reportWebVitals';// Import ajusté avec le nouveau nom
import Head from './dependanceHead/Head';

const head = ReactDOM.createRoot(document.getElementById('head'));
head.render(
  <React.StrictMode>
    <Head />
  </React.StrictMode>
);


const header = ReactDOM.createRoot(document.getElementById('header'));
header.render(
  <React.StrictMode>
    <Header />
  </React.StrictMode>
);

const app = ReactDOM.createRoot(document.getElementById('app'));
app.render(
  <React.StrictMode>
    <AppMain /> {/* Charger les scripts globalement */}
  </React.StrictMode>
);

const footer = ReactDOM.createRoot(document.getElementById('footer'));
footer.render(
  <React.StrictMode>
    <Footer />
  </React.StrictMode>
);

reportWebVitals();
