import React from 'react';

function ErrorSection() {
  return (
    <section className="error-section centred">
      <div className="scroll-text">
        <div className="text-box-one">
          <div className="text-inner text-one">
            {/* Répéter le message d'erreur en utilisant le prop errorMessage */}
            {Array.from({ length: 24 }).map((_, index) => (
              <h3 key={index}>Page non trouvée</h3>
            ))}
          </div>
        </div>
        <div className="text-box-two">
          <div className="text-inner text-two">
            {/* Répéter le message d'erreur en utilisant le prop errorMessage */}
            {Array.from({ length: 24 }).map((_, index) => (
              <h3 key={index}>Page non trouvée</h3>
            ))}
          </div>
        </div>
      </div>
      <div className="auto-container">
        <div className="content-box">
          <div className="image-box">
            <h3>Oops!</h3>
            <figure className="error-image">
              <img src="assets/images/icons/error-1.png" alt="" />
            </figure>
          </div>
          <h2>Quelque chose ne va pas, Rééssayez plus tard</h2>
          <p>
            Peut-être avez vous besoin d'assistance.
          </p>
          <div className="btn-box">
            <a href="/" className="theme-btn btn-two">
              Accueil
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ErrorSection;
