import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import routesConfig from './routes';
const router = createBrowserRouter(
  routesConfig.map(({ path,  component: Component}) => ({
    path, // Utilisation de raccourci ES6 pour { path: path }
    element: <Component />,
    // errorElement: <MonComposantErreur /> // Décommentez si vous avez un composant d'erreur spécifique
  }))
);

function AppMain() {
    return (
        <RouterProvider router={router} />
  
    );
  }
  
  export default AppMain;