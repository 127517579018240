import React from 'react';
import Loader from '../Loader';
const menuItems = [
  {
    label: 'Accueil',
    href: '/accueil'
  },
  {
    label: 'Investissements',
    href: '/investissements',
    children: [
      { label: 'Catalogue des Véhicules', href: 'catalogue-vehicules.html' },
      { label: 'Camions', href: 'camions.html' },
      { label: 'Tricycles', href: 'tricycles.html' },
      { label: 'Équipements de BTP', href: 'equipements-btp.html' }
      // Other submenus if necessary...
    ],
  },
  {
    label: 'Simulateur',
    href: '/simulateur'
  },
  {
    label: 'À Propos',
    href: '/apropos'
  },
  {
    label: 'FAQ',
    href: '/faq'
  },
  {
    label: 'Contact',
    href: '/contact'
  }
  // Other menu items if necessary...
];

function SearchPopup() {
  return (
    <div id="search-popup" className="search-popup">
      <div className="popup-inner">
        <div className="upper-box clearfix">
          <figure className="logo-box pull-left">
            <a href="index.html">
              <img src="assets/images/logo.png" alt="" />
            </a>
          </figure>
          <div className="close-search pull-right">
            <i className="fa-solid fa-xmark" />
          </div>
        </div>
        <div className="overlay-layer" />
        <div className="auto-container">
          <div className="search-form">
            <form method="post" action="index.html">
              <div className="form-group">
                <fieldset>
                  <input
                    type="search"
                    className="form-control"
                    name="search-input"
                    defaultValue=""
                    placeholder="Type your keyword and hit enter"
                    required=""
                  />
                  <button type="submit">
                    <i className="flaticon-loupe" />
                  </button>
                </fieldset>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

function SidebarCart() {
  return (
    <div className="xs-sidebar-group info-group info-sidebar">
      <div className="xs-overlay xs-bg-black" />
      <div className="xs-sidebar-widget">
        <div className="sidebar-widget-container">
          <div className="widget-heading">
            <a href="#/" className="close-side-widget">
              <i className="fa fa-times" />
            </a>
          </div>
          <div className="sidebar-textwidget">
            <div className="sidebar-info-contents">
              <div className="content-inner">
                <div className="logo">
                  <a href="index.html">
                    <img src="assets/images/logo.png" alt="" />
                  </a>
                </div>
                <div className="content-box">
                  <h4>About Us</h4>
                  <p>
                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                    accusantium doloremque laudantium, totam rem aperiam, eaque
                    ipsa quae ab illo inventore veritatis et quasi
                  </p>
                  <p>
                    Research oriented solutions for Data Science and Machine
                    Learning business needs.
                  </p>
                  <a href="about.html" className="theme-btn btn-two">
                    About Us
                  </a>
                </div>
                <div className="contact-info">
                  <h4>Contact Info</h4>
                  <ul>
                    <li>Chicago 12, Melborne City, USA</li>
                    <li>
                      <a href="tel:+8801682648101">+88 01682648101</a>
                    </li>
                    <li>
                      <a href="mailto:info@example.com">info@example.com</a>
                    </li>
                  </ul>
                </div>
                <ul className="social-box clearfix">
                <li>
    <a href="index.html">
      <span className="fab fa-twitter" />
    </a>
  </li>
  <li>
    <a href="index.html">
      <span className="fab fa-facebook-square" />
    </a>
  </li>
  <li>
    <a href="index.html">
      <span className="fab fa-pinterest-p" />
    </a>
  </li>
  <li>
    <a href="index.html">
      <span className="fab fa-instagram" />
    </a>
  </li>
  <li>
    <a href="index.html">
      <span className="fab fa-youtube" />
    </a>
  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function HeaderTop() {
  return (
      <div className="header-top">
          <div className="outer-container">
              <ul className="info-list clearfix">
              <li>
                <a href="index.html">News &amp; Media</a>
              </li>
              <li>
                <a href="index.html">Investors</a>
              </li>
              <li>
                <a href="index.html">Downloads</a>
              </li>
              <li>
                <a href="index.html">Career</a>
              </li>
            </ul>
            <ul className="social-links clearfix">
              <li>
                <h5>Social Connect</h5>
              </li>
              <li>
                <a href="index.html">
                  <i className="fa-brands fa-facebook" />
                </a>
              </li>
              <li>
                <a href="index.html">
                  <i className="fa-brands fa-square-twitter" />
                </a>
              </li>
              <li>
                <a href="index.html">
                  <i className="fa-solid fa-basketball" />
                </a>
              </li>
              <li>
                <a href="index.html">
                  <i className="fa-brands fa-youtube" />
                </a>
              </li>
              </ul>
              
          </div>
      </div>
  );
}
function HeaderUpper() {
  return (
      <div className="header-upper">
          <div className="outer-container">
            <div className="left-column">
              <figure className="logo-box">
                <a href="index.html">
                  <img src="assets/images/logo.png" alt="" />
                </a>
              </figure>
              <div className="award-box">
                <div className="award-image">
                  <img src="assets/images/icons/award-1.png" alt="" />
                </div>
                <h5>
                  Leading providers of investment <br />
                  advisory solutions.
                </h5>
              </div>
            </div>
            <div className="right-column">
              <div className="support-box">
                <div className="icon-box">
                  <i className="flaticon-headphone" />
                </div>
                <h5>
                  <a href="faq.html">
                    Have <br />
                    Question?
                  </a>
                </h5>
              </div>
              <div className="phone">
                <h3>
                  <a href="tel:4188822333">(+229) 67.34.41.34</a>
                </h3>
              </div>
              <div className="btn-box">
                <a href="index.html" className="theme-btn btn-one">
                  Rendez-vous
                </a>
              </div>
            </div>
          </div>
      </div>
  );
}
function NavMenu({ children }) {
  return (
    <nav className="main-menu navbar-expand-md navbar-light">
      <div className="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
        <ul className="navigation clearfix">
          {children}
        </ul>
      </div>
    </nav>
  );
}

function MenuItem({ label, href, children }) {
  const hasChildren = children && children.length > 0;
  return (
    <li className={`dropdown ${hasChildren ? 'has-submenu' : ''}`}>
      <a href={href}>{label}</a>
      {hasChildren && (
        <ul>
          {children.map((child, index) => (
            <MenuItem key={index} {...child} />
          ))}
        </ul>
      )}
    </li>
  );
}
function ElementMenu() {
  return (
    <div>
      {menuItems.map((item, index) => (
        <MenuItem key={index} {...item} />
      ))}
    </div>
  );
  
}

function HeaderLower() {
  

  return (
    <div className="header-lower">
      <div className="outer-container">
        <div className="outer-box">
          <div className="menu-area clearfix">
            <div className="mobile-nav-toggler">
              <i className="icon-bar" />
              <i className="icon-bar" />
              <i className="icon-bar" />
            </div>
            <NavMenu>
              {menuItems.map((item, index) => (
                <MenuItem key={index} {...item} />
              ))}
            </NavMenu>
          </div>
          <div className="menu-right-content">
                <div className="search-box">
                  <div className="search-box-outer search-toggler">
                    <img src="assets/images/icons/icon-2.png" alt="" />
                    Search
                  </div>
                </div>
                <div className="language-box">
                  <h5>
                    <img src="assets/images/icons/icon-3.png" alt="" />
                    Global:
                  </h5>
                  <div className="select-box">
                    <select className="selectmenu">
                      <option>Eng</option>
                      <option>Chines</option>
                      <option>Hindi</option>
                      <option>Turky</option>
                    </select>
                  </div>
                </div>
                <div className="nav-btn nav-toggler navSidebar-button clearfix">
                  <img src="assets/images/icons/icon-4.png" alt="" />
                </div>
              </div>
        </div>
      </div>
    </div>
  );
}


function MobileMenu() {
  // Ici, vous pourriez ajouter la logique pour ouvrir/fermer le menu mobile
  return (
    <div className="mobile-menu">
      <div className="menu-backdrop" />
      <div className="close-btn">
        <i className="fas fa-times" />
      </div>
      <nav className="menu-box">
        <div className="nav-logo">
          <a href="index.html">
            <img src="assets/images/logo-4.png" alt="" title="" />
          </a>
        </div>
        <div className="menu-outer">
          {/* Ici, le menu viendra automatiquement via JavaScript / Même menu que dans l'en-tête */}
        </div>
        <div className="contact-info">
          <h4>Contact Info</h4>
          <ul>
            <li>Chicago 12, Melborne City, USA</li>
            <li>
              <a href="tel:+8801682648101">+88 01682648101</a>
            </li>
            <li>
              <a href="mailto:info@example.com">info@example.com</a>
            </li>
          </ul>
        </div>
        <div className="social-links">
          <ul className="clearfix">
            {/* Les liens sociaux ici */}
          </ul>
        </div>
      </nav>
    </div>
  );
}

function Header1() {
    return (
      <>
      <Loader/>
      <header className="main-header">
      <SearchPopup />
      <SidebarCart />
      <HeaderTop />
      <HeaderUpper />
      <HeaderLower />
      </header>
      <MobileMenu />
      </>

    );
}

export default Header1;
export { ElementMenu };