import React from 'react';
import Module from '../dependanceJs/ModuleJs'; 
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import routesConfig from '../routes';
const router = createBrowserRouter(
  routesConfig.map(({ path, footer: Footer }) => ({
    path, // Utilisation de raccourci ES6 pour { path: path }
    element: <Footer />,
    // errorElement: <MonComposantErreur /> // Décommentez si vous avez un composant d'erreur spécifique
  }))
);

function Footer() {
    return (
      <>
      <Module/>
      <RouterProvider router={router} />
      </>
        
  
    );
  }
  
  export default Footer;