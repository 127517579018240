import React from 'react';
import {ElementMenu} from '../headers/Header1.js';
function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Pour un défilement doux
    });
  }
function Footer1() {
    
    return (
        <>
  {/* main-footer */}
  <section className="main-footer">
    <div className="auto-container">
      <div className="footer-top">
        <div className="row clearfix">
          <div className="col-lg-6 col-md-12 col-sm-12 footer-column">
            <div className="logo-widget">
              <figure className="footer-logo">
                <a href="index.html">
                  <img src="assets/images/logo.png" alt="" />
                </a>
              </figure>
              <div className="copyright">
                <h5>
                  Copyright © 2023 <a href="index.html">Counsolve.</a> All
                  Rights Reserved.
                </h5>
              </div>
              <div className="text-box">
                <p>
                  Provides advice &amp; guidance to clients regarding their
                  investments &amp; manage their investment portfolios.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 footer-column">
            <div className="newsletter-widget">
              <div className="icon-box">
                <img src="assets/images/icons/icon-8.png" alt="" />
              </div>
              <h3>Newsletter</h3>
              <p>
                By submitting this form, you consent to receive marketing
                updates from Counsolve.
              </p>
              <div className="form-inner">
                <form method="post" action="contact.html">
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter Email Address..."
                      required=""
                    />
                    <button type="submit" className="theme-btn btn-one">
                      Subscribe
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="widget-section">
        <div className="row clearfix">
          <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
            <div className="footer-widget contact-widget">
              <div className="widget-title">
                <h3>Office Location</h3>
              </div>
              <div className="widget-content">
                <h4>
                  <span>Over 30 Offices in more</span>
                  <br /> <span>than 16 Countries</span>
                </h4>
                <h5>Head Office</h5>
                <p>
                  280 Granite Run Drive Suite <br />
                  Hobert, LA 90010, <br />
                  United States.
                </p>
                <div className="map-box">
                  <div className="icon-box">
                    <i className="fas fa-map-location" />
                  </div>
                  <h6>
                    <a
                    rel="noopener noreferrer"
                      href="https://www.google.com/maps/@23.235815,89.5731704,17.1z"
                      target="_blank"
                    >
                      Google Map
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
            <div className="footer-widget links-widget">
              <div className="widget-title">
                <h3>Auto Partage Invest</h3>
              </div>
              <div className="widget-content">
                <ul className="links-list clearfix">
                <ElementMenu />
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
            <div className="footer-widget links-widget">
              <div className="widget-title">
                <h3>Essentials</h3>
              </div>
              <div className="widget-content">
                <ul className="links-list clearfix">
                  <li>
                    <a href="index.html">How it’s Work</a>
                  </li>
                  <li>
                    <a href="index.html">Partners</a>
                  </li>
                  <li>
                    <a href="index.html">Testimonials</a>
                  </li>
                  <li>
                    <a href="index.html">Case Studies</a>
                  </li>
                  <li>
                    <a href="index.html">Free Quote</a>
                  </li>
                  <li>
                    <a href="index.html">Plan &amp; Pricing</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
            <div className="footer-widget post-widget">
              <div className="widget-title">
                <h3>Popular Post</h3>
              </div>
              <div className="post-inner">
                <div className="post">
                  <span className="category">Investment</span>
                  <h4>
                    <a href="blog-details.html">
                      Revisiting Your Investment &amp; Distribution Goals
                    </a>
                  </h4>
                </div>
                <div className="post">
                  <span className="category">Business</span>
                  <h4>
                    <a href="blog-details.html">
                      Dimensional Fund Advisors Interview with Director
                    </a>
                  </h4>
                </div>
                <div className="link-box">
                  <a href="blog.html">
                    <span>View All Post</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-bottom">
      <div className="auto-container">
        <div className="bottom-inner">
          <ul className="footer-nav">
            <li>
              <a href="index.html">Terms &amp; Conditions</a>
            </li>
            <li>
              <a href="index.html">Privacy Policy</a>
            </li>
            <li>
              <a href="index.html">Sitemap</a>
            </li>
            <li>
              <a href="index.html">Investor Policy</a>
            </li>
          </ul>
          <button className="scroll-to-target" onClick={scrollToTop}>
        Back to Top
        <i className="flaticon-up-arrow" />
      </button>
        </div>
      </div>
    </div>
  </section>
  {/* main-footer end */}
</>

    );
}

export default Footer1;
