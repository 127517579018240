import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import routesConfig from '../routes';
const router = createBrowserRouter(
  routesConfig.map(({ path, dependanceJs: DependanceJs }) => ({
    path, // Utilisation de raccourci ES6 pour { path: path }
    element: <DependanceJs />,
    // errorElement: <MonComposantErreur /> // Décommentez si vous avez un composant d'erreur spécifique
  }))
);

function Module() {
    return (
        <RouterProvider router={router} />
  
    );
  }
  
  export default Module;