import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Head2 = () => {
  return (
    <HelmetProvider>
    <Helmet>
      <link rel="shortcut icon" href="vue1/images/favicon.ico" />
  {/* bootstrap */}
  <link rel="stylesheet" type="text/css" href="vue1/css/bootstrap.min.css" />
  {/* flaticon */}
  <link rel="stylesheet" type="text/css" href="vue1/css/flaticon.css" />
  {/* mega menu */}
  <link rel="stylesheet" type="text/css" href="vue1/css/mega-menu/mega_menu.css" />
  {/* mega menu */}
  <link rel="stylesheet" type="text/css" href="vue1/css/font-awesome.min.css" />
  {/* owl-carousel */}
  <link
    rel="stylesheet"
    type="text/css"
    href="vue1/css/owl-carousel/owl.carousel.css"
  />
  {/* revolution */}
  <link rel="stylesheet" type="text/css" href="vue1/revolution/css/settings.css" />
  {/* animate */}
  <link rel="stylesheet" type="text/css" href="vue1/css/animate.min.css" />
  {/* main style */}
  <link rel="stylesheet" type="text/css" href="vue1/css/style.css" />
  {/* responsive */}
  <link rel="stylesheet" type="text/css" href="vue1/css/responsive.css" />

    </Helmet>
    </HelmetProvider>
  );
}

export default Head2;
