import React from 'react';
import { Helmet,HelmetProvider } from 'react-helmet-async';

const Head1 = () => {
  return (
    <HelmetProvider>
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap"
        rel="stylesheet"
      />
      <link href="assets/css/font-awesome-all.css" rel="stylesheet" />
      <link href="assets/css/flaticon.css" rel="stylesheet" />
      <link href="assets/css/owl.css" rel="stylesheet" />
      <link href="assets/css/bootstrap.css" rel="stylesheet" />
      <link href="assets/css/jquery.fancybox.min.css" rel="stylesheet" />
      <link href="assets/css/animate.css" rel="stylesheet" />
      <link href="assets/css/nice-select.css" rel="stylesheet" />
      <link href="assets/css/color.css" rel="stylesheet" />
      <link href="assets/css/style.css" rel="stylesheet" />
      <link href="assets/css/responsive.css" rel="stylesheet" />
    </Helmet>
    </HelmetProvider>
  );
}

export default Head1;
